.position {

    box-shadow: 0 4px 40px rgba(14, 21, 85, 0.1);
    border-radius: 3px;
    padding: 18px 31px;
    margin-top: 5px;
    margin-bottom: 5px;

    @include media-breakpoint-between(md, lg) {
        padding: 21px 60px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    @include media-breakpoint-up(lg) {
        padding: 26px 67px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    &-third {
        display: none;

        @include media-breakpoint-up(md) {
            display: block;
        }
    }

    &__name {
        font-size: 12px;

        @include media-breakpoint-between(md, lg) {
            font-size: 14px;
        }

        @include media-breakpoint-up(lg) {
            font-size: 18px;

        }
    }

    &--even {
        transition: all .3s;
        background: #FFFFFF;
        font-weight: $font-weight-bold;
        color: $title;

        .label {
            color: $body-color;
        }

        .position__link {
            background: rgba(29,15,81,0.05);

            &__icon {
                background-color: $title;
            }
        }

        &:hover {
            cursor: pointer;
            background: $first-button;
            font-weight: $font-weight-bold;
            color: $white;

            .label {
                color: inherit;
            }

            .position__link {
                background: rgba(255,255,255,0.2);

                &__icon {
                    background-color: $white;
                }
            }
        }
    }

    &--odd {
        background: $first-button;
        font-weight: $font-weight-bold;
        color: $white;

        .label {
            color: inherit;
        }

        .position__link {
            background: rgba(255,255,255,0.2);

            &__icon {
                background-color: $white;
            }
        }
    }

    &__location {
        font-weight: $font-weight-regular;
        letter-spacing: 0.01em;
        margin-left: 9px;
        line-height: 18px;
        font-size: 10px;

        @include media-breakpoint-between(md,lg) {
            margin-left: 22px;
            line-height: 21px;
            font-size: 12px;
        }

        @include media-breakpoint-up(lg) {
            margin-left: 15px;
            line-height: 27px;
            font-size: 12px;
        }
    }

    &__link {
        padding: 0;

        &__icon {
            display: flex;
            width: 24px;
            height: 24px;
            border-radius: 100px;
            margin: 0;
            mask-size: 20%;

            @include media-breakpoint-up(md) {
                width: 34px;
                height: 34px;
            }
        }
    }

    &__showmore-link {
        margin-top: 15px;

        @include media-breakpoint-between(md,lg){
            margin-top: 10px;
        }

        @include media-breakpoint-up(lg){
            margin-top: 20px;
        }

        &--more-than-2 {
            display: block;

            @include media-breakpoint-up(md) {
                display: none;
            }
        }

        &--more-than-3 {
            display: block;
        }
    }
}
