.ticket-details {
		position: relative;
		&__header {
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				padding-top: 18px;

				.checkout__last-checkpoint & {
						@include media-breakpoint-up(md) {
								margin-top: 25px;
						}
				}

				&--final {
						@include media-breakpoint-up(md) {
								align-items: center;
								padding-bottom: 20px;
						}
				}

				&__wrapper {
						display: flex;

						&__image {
								width: 54px;
								height: 54px;

								&--final {
										@include media-breakpoint-up(md) {
												width: 100px;
												height: 100px;
										}

										&-ticket {
												@include media-breakpoint-up(md) {
														padding: 20px;
												}
										}
								}
						}

						&__title {
								font-size: 16px;
								margin-left: 25px;
								margin-top: 4px;
								color: $title;
								justify-content: center;
								align-items: center;

								@include media-breakpoint-between(md, lg) {
										margin-left: 17px;
								}

								@include media-breakpoint-up(lg) {
										margin-left: 25px;
								}

								@include media-breakpoint-up(xl) {
										margin-top: 8px;
										margin-bottom: 6px !important;
										margin-left: 0;
								}

								&--final {
										margin-left: 0;
										margin-top: 10px;

										@include media-breakpoint-up(md) {
												margin-top: 0;
										}
								}
						}
				}

				&__element {
						display: flex;
						flex-direction: column;

						&--final {
								flex-direction: row;

								@include media-breakpoint-up(md) {
										align-items: center;
								}
						}

						@include media-breakpoint-up(lg) {
								&:first-child {
										flex-direction: row;
								}
						}
				}

				&__quantity {
						margin-top: 8px;
						margin-right: 13px;

						@include media-breakpoint-up(lg) {
								margin-right: 13px;
						}

						.checkout__last-checkpoint & {
								margin-right: 13px;
						}

						&--final {
								@include media-breakpoint-up(md) {
										margin-top: 25px;
								}
						}
				}

				&__title {
						margin-bottom: 0;

						//@include media-breakpoint-between(md,lg) {
						//		max-width: 125px;
						//}

						&__text {
							font-family: 'Montserrat', serif;
							font-size: 12px;
							font-style: normal;
							font-weight: 500;
							color: #5E7891;
						}

						@include media-breakpoint-up(lg) {
								font-size: 18px;
								font-weight: $font-weight-regular;

								.ticket-details__body__label--first-line & {
										font-size: inherit;
								}
						}

						.checkout__last-checkpoint & {
								font-size: 18px;
								color: $headings-color;
								font-weight: $font-weight-regular;
						}
				}

				&__ticket {
						display: flex;
				}

				&__price {
						font-size: 14px;
						line-height: 24px;
						margin-bottom: 0;
						background: $button;
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;

						.checkout__last-checkpoint & {
								font-size: 14px;

								@include media-breakpoint-up(md) {
										min-width: 70px;
										text-align: right;
								}
						}
				}

				&__label {
						display: none !important;

						.checkout__last-checkpoint & {
								@include media-breakpoint-up(md) {
										display: inline-block !important;
										margin-left: 5px;
								}
						}
				}
		}

		&__body {
			margin: 4px 17px 12px 25px;

			@include media-breakpoint-between(md, lg) {
						margin-left: 17px;
				}

				@include media-breakpoint-up(lg) {
						margin: 4px 25px 8px;
				}

				@include media-breakpoint-up(xl) {
						margin-top: 8px;
						margin-left: 6px;
						max-width: 165px;
						margin-bottom: 12px;
				}

				&--final {
						margin-left: 12px !important;
						margin-bottom: 12px !important;
						max-width: 370px;

						@include media-breakpoint-up(md) {
								max-width: unset;
								margin-bottom: 0 !important;
								margin-left: 20px !important;
						}
				}

				.checkout__last-checkpoint & {
						margin: 0 0 8px 25px;
				}

				&__subscription {
						margin-top: 8px;
				}

				&__label {
						font-weight: $font-weight-medium;
						color: $body-color;
						font-size: 10px;
						line-height: normal;

						@include media-breakpoint-up(lg) {
								font-weight: $font-weight-regular;
						}


						&--place {
								@include media-breakpoint-up(md) {
										margin-bottom: 5px;
								}
						}

						&--seat-group {
								display: block;

								@include media-breakpoint-up(md) {
										margin-top: 5px;
										//display: flex;
										//flex-direction: column;
								}

								//@include media-breakpoint-up(lg) {
										//flex-direction: row;
								//}
						}

						&--first-line {
								white-space: pre-line;
								display: block;

								.checkout__last-checkpoint & {
										font-size: 12px;
										font-weight: $font-weight-regular;

										@include media-breakpoint-up(md) {
												display: none;
										}

										&--final {
												display: block;
										}
								}
						}

						&--date {
								display: inline;
								text-transform: capitalize;
						}

						&--stadium {
								display: inline;
								&:before {
										content: ", ";
								}

								&--final::before {
										content: none
								}

								@include media-breakpoint-between(md,lg) {
										display: block;
										&:before {
												content: none;
										}

										.checkout__last-checkpoint & {
												display: inline-block;
												&:before {
														content: ", ";
												}
										}
								}
						}

						&--sector {
								display: block;

								.checkout__last-checkpoint & {
										display: inline-block;
								}
						}

						&--seat {
								display: block;

								.checkout__last-checkpoint & {
										display: inline-block;

										@include media-breakpoint-up(lg) {
												&:before {
														content: ", ";
												}
										}
								}
						}
				}
		}

		&__footer {
				display: flex;
				flex-direction: column;
				margin: 14px 5px;
				border-bottom: 1px solid #E0E0E0;

				.checkout__last-checkpoint & {

						@include media-breakpoint-up(md) {
								flex-direction: row;
								align-items: baseline;
						}
				}

				&__label {
						&--user {
								display: block;

								.checkout__last-checkpoint & {
										display: inline-block;
								}
						}

						&--birthdate {
								display: block;

								.checkout__last-checkpoint & {
										display: inline-block;

										&:before {
												content: "-";
												margin-left: 2px;
												margin-right: 2px;
										}
								}
						}
				}

				.checkout__last-checkpoint & {
						margin: 0 0 0 25px;
				}

				&__title {
						font-weight: $font-weight-semi-bold;
						line-height: normal;
						font-size: 10px;
						text-transform: uppercase;

						@include media-breakpoint-up(lg) {
								font-size: 13px;
						}

						.checkout__last-checkpoint & {
								@include media-breakpoint-up(md) {
										&:after {
												content: ":";
												margin-right: 10px;
										}
								}
						}
				}

				&__content {
						font-weight: $font-weight-medium;
						line-height: 15px;
						font-size: 12px;
						//color: $headings-color;
						color: $title;

						&--empty {
								line-height: 20px;
								color: #F23254;
						}

						@include media-breakpoint-up(lg) {
								font-size: 12px;
						}
				}
		}
}
