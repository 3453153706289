.error-modal {

		&__modal {
				z-index: 1050;
		}

		&__image {
				margin-top: 14px;
				margin-bottom: 16px;
				@include media-breakpoint-up(md) {
						margin-top: 24px;
						margin-bottom: 20px;
				}
		}

		&__title {
				@include media-breakpoint-down(sm) {
						font-size: 16px;
						margin-bottom: 4px;
				}
				margin-bottom: 6px;
		}

		&__paragraph {
				@include media-breakpoint-down(sm) {
						font-size: 14px;
						margin-bottom: 20px;
				}
				margin-bottom: 30px;
		}

		&__btn {
				width: 160px;
				padding: 3px !important;
				margin-bottom: 14px;
		}
}