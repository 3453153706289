.document-upload-modal {
    @include media-breakpoint-between(md, lg) {
        width: 450px;
    }

    @include media-breakpoint-up(lg) {
        width: 540px;
    }

    &__body {
        min-height: 250px;
        @include media-breakpoint-down(md) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__content {
        height: unset;

        &__subtitle {
            @include media-breakpoint-between(md, lg) {
                padding: 0 25px;
            }
        }
    }

    &__progress {
        display: flex;
        justify-content: space-between;
        position: relative;
        padding: 0 20px;
        margin-top: 27px;

        @include media-breakpoint-between(md, lg) {
            margin-left: 35px;
            margin-right: 35px;
        }

        @include media-breakpoint-up(lg) {
            margin-left: 45px;
            margin-right: 45px;
        }

        &::after {
            content: ' ';
            position: absolute;
            height: 1px;
            width: calc(100% - 130px);
            background: $headings-color;
            opacity: 0.1;
            border-radius: 2px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &__step {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            font-size: 14px;
            height: 25px;
            width: 25px;
            background: #FFFFFF;
            box-shadow: 0 4px 40px rgba(14, 21, 85, 0.1);
            border-radius: 100px;

            color: $body-color;
            font-weight: 500;
            cursor: pointer;

            &:hover{
                &:not(.disabled){
                    background: $headings-color !important;
                    color: $white !important;
                }
            }

            &__label {
                position: absolute;
                color: $main-text;
                bottom: -15px;
                margin-bottom: 0;
                font-weight: 500;
                line-height: normal;
                font-size: 10px;
                text-align: center;
                left: 50%;
                transform: translate(-50%, 0);
            }

            &.active {
                background: $headings-color;
                color: white;
                font-weight: bold;
            }
        }
    }

    &__upload-area {
        display: flex;
        justify-content: center;
        cursor: pointer;
        margin-top: 48px;
        margin-bottom: 21px;
        height: 194px;
        background: url("../../img/icon/doc-up-bg.svg") no-repeat center, rgba(94, 120, 145, 0.03);
        border: 2px dashed rgba(94, 120, 145, 0.24);

        &.active {
            background: rgba(255, 255, 255, 0.03);
            border: 2px solid rgba(94, 120, 145, 0.24);
        }

        @include media-breakpoint-up(md) {
            max-width: 330px;
            margin-left: auto;
            margin-right: auto;
        }

        @include media-breakpoint-up(lg) {
            max-width: 350px;
        }

        & * {
            pointer-events: none
        }

        &.dragging {
            //TO DO
        }

        &__document {
            padding: 0 25px;
            width: 100%;
            object-fit: scale-down;
        }

        &__label {
            margin-top: auto;
            margin-bottom: 50px;
            line-height: 24px;
        }
    }

    &__edit-buttons {
        display: flex;
        justify-content: center;
        margin-bottom: 15px;

        &__change,
        &__delete {
            display: flex;
            align-items: center;
            line-height: 24px;
            font-size: 14px;
        }

        &__change {
            margin-right: 45px;
        }

        &__delete {
            color: #F23254 !important;
        }
    }
}
