.ticket-sell {
    margin-top: 60px;
    text-align: center;
    min-height: 600px;
    margin-bottom: 30px;

    @include media-breakpoint-up(md){
        text-align: left;
    }
    @include media-breakpoint-between(md, lg){
        text-align: left;
        padding-top: 35px;
        height: 420px;
        background-position: calc(100% + 70px) calc(50% - 10px);
        background-size: calc(60%);
        margin-top: 110px;
        margin-bottom: 150px;
    }

    @include media-breakpoint-between(lg, xl){
        text-align: left;
        margin-top: 50px;
        height: 550px;
        margin-bottom: 150px;
    }

    @include media-breakpoint-up(xl){
        height: 768px;
        background-position: calc(100% + 5vw) calc(50% - 1.5vw);
        background-size: calc(65%);
        margin-bottom: 100px;
    }

    &__explain-header {
        margin-bottom: 20px;
        //font-weight: 500;
    }

    &__explain-paragraph {
        line-height: 20px;

        @include media-breakpoint-up(md){
            line-height: 24px;
        }
    }

    &__button {
        margin-top: 20px;
        //box-shadow: 0px 9px 28px rgba(108, 197, 111, 0.3);
        font-size: 12px !important;

        @include media-breakpoint-between(md, lg) {
            width: 210px;
            margin-top: 24px;
        }

        @include media-breakpoint-up(lg) {
            max-width: 179px;
            margin-top: 44px;
        }
    }

    &__image {
        @include media-breakpoint-between(md, lg){
            @include make-columns(6);
        }

        @include media-breakpoint-up(lg){
            @include make-columns(6,1);
        }
    }
}
