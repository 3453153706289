.signup-in {


		@include media-breakpoint-only(xs) {
			display: grid;
			width: 95vw;
		}

		&__section {
			max-width: 450px !important;
		}

		&__accordion {
						&__icon {
								//&.icon--arrow {
								//		right: -50px;
								//}

								&.collapsed {
										&.icon--arrow {
												transform: rotate(0);
										}
								}
						}
		}

		/* enable absolute positioning */
		.inner-addon {
				position: relative;
		}

		/* style icon */
		.inner-addon .glyphicon {
				//position: absolute;
				padding: 10px;
				pointer-events: none;
		}

		/* align icon */
		.left-addon .glyphicon  { left:  0;}
		.right-addon .glyphicon { right: 0;}

		/* add padding  */
		.left-addon input  { padding-left:  30px; }
		.right-addon input { padding-right: 30px; }

		&__content {
				&__form-list {
						margin-bottom: 40px;
				}

				&--max {
						max-width: 350px;

						.form-check-label {
								padding-right: 36px;
						}
				}
		}

		//For Devices smaller than 768px
		@include media-breakpoint-down(sm) {
				margin-top: 20px;
				margin-bottom: 20px;
		}

		&__title {
				font-weight: 400;
				margin-bottom: 10px;

				//For Devices smaller than 768px
				@include media-breakpoint-up(md) {
						margin-bottom: 30px;
				}

				//For Devices smaller than 1200px
				@include media-breakpoint-up(lg) {
						margin-bottom: 20px;
				}
		}

		&__description {
			margin-bottom: 20px;
			color: $main-text;

			//For Devices smaller than 768px
			@include media-breakpoint-up(md) {
					margin-bottom: 30px;
			}
			&__small {
				@include media-breakpoint-only(xs) {
					margin-bottom: 0;
				}
			}
		}

		&__separator {
				margin-top: 20px;
				margin-bottom: 20px;
		}

		&__container {
			min-height: 90vh;
			@include media-breakpoint-up(sm) {
				max-width: 100vw;
			}
			@include media-breakpoint-up(md) {
				max-width: 90vw;
			}
			@include media-breakpoint-up(lg) {
				max-width: 85vw;
			}
			@include media-breakpoint-up(xl) {
				width: 85vw;
				max-width: 1600px !important;
			}
		}

		&__box-lost-password {
				margin-top: 10px;
		}

		&__lost-password {
				font-size: 12px;
				line-height: 22px;
				text-decoration-line: underline;
				color: $body-color;
				margin-top: 15px;
		}

		&__btn--gradient {
			font-size: 13px;
			@include media-breakpoint-only(xs) {
				width: 100%
			}
		}

		.btn--facebook {
				margin-bottom: 20px;
		}

		.form-group {
				margin-bottom: 20px;
		}

		label {
				font-weight: 500;
				font-size: 12px;
				text-transform: uppercase;
		}

		&__disclaimer {
				line-height: 20px;
				font-size: 12px;
		}

		.form-check-label {
				text-transform: none;
		}
}
