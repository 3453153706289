.footer {
	background: url("data:image/svg+xml;utf8,%3Csvg%20width%3D%22952%22%20height%3D%22796%22%20viewBox%3D%220%200%20952%20796%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M23.1211%2074.1251C8.29657%20222.019%20-20.5062%20641.205%2023.1216%20792.837L875.468%20796C875.468%20796%201050.2%20173.747%20871.619%2074.125C693.034%20-25.4971%20214.736%20-23.9164%2023.1211%2074.1251Z%22%20fill%3D%22url%28%23paint0_linear%29%22/%3E%3Cdefs%3E%3ClinearGradient%20id%3D%22paint0_linear%22%20x1%3D%2232.5981%22%20y1%3D%2281.2434%22%20x2%3D%22914.254%22%20y2%3D%22194.414%22%20gradientUnits%3D%22userSpaceOnUse%22%3E%3Cstop%20stop-color%3D%22#{$second-footer}%22/%3E%3Cstop%20offset%3D%221%22%20stop-color%3D%22#{$first-footer}%22/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A") center 0 no-repeat;
	background-size: auto 110%;
	width: 100%;

	//For Devices with width between 768px and 992px
	@include media-breakpoint-up(md) {
		background: url("data:image/svg+xml;utf8,%3Csvg%20width%3D%22952%22%20height%3D%22796%22%20viewBox%3D%220%200%20952%20796%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M23.1211%2074.1251C8.29657%20222.019%20-20.5062%20641.205%2023.1216%20792.837L875.468%20796C875.468%20796%201050.2%20173.747%20871.619%2074.125C693.034%20-25.4971%20214.736%20-23.9164%2023.1211%2074.1251Z%22%20fill%3D%22url%28%23paint0_linear%29%22/%3E%3Cdefs%3E%3ClinearGradient%20id%3D%22paint0_linear%22%20x1%3D%2232.5981%22%20y1%3D%2281.2434%22%20x2%3D%22914.254%22%20y2%3D%22194.414%22%20gradientUnits%3D%22userSpaceOnUse%22%3E%3Cstop%20stop-color%3D%22#{$second-footer}%22/%3E%3Cstop%20offset%3D%221%22%20stop-color%3D%22#{$first-footer}%22/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A") center 0 no-repeat;
		//background-size: 540px,
		background-size: 150% auto;
	}

	//For Devices larger than 992px
	@include media-breakpoint-up(lg) {
		background: url("data:image/svg+xml;utf8,%3Csvg%20width%3D%22952%22%20height%3D%22796%22%20viewBox%3D%220%200%20952%20796%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M23.1211%2074.1251C8.29657%20222.019%20-20.5062%20641.205%2023.1216%20792.837L875.468%20796C875.468%20796%201050.2%20173.747%20871.619%2074.125C693.034%20-25.4971%20214.736%20-23.9164%2023.1211%2074.1251Z%22%20fill%3D%22url%28%23paint0_linear%29%22/%3E%3Cdefs%3E%3ClinearGradient%20id%3D%22paint0_linear%22%20x1%3D%2232.5981%22%20y1%3D%2281.2434%22%20x2%3D%22914.254%22%20y2%3D%22194.414%22%20gradientUnits%3D%22userSpaceOnUse%22%3E%3Cstop%20stop-color%3D%22#{$second-footer}%22/%3E%3Cstop%20offset%3D%221%22%20stop-color%3D%22#{$first-footer}%22/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A") center 0 no-repeat;
		//background-size: 1200px,
		background-size: 300% auto;
	}

	&__container {
		margin-bottom: 0 !important;
	}

	&__row {
		padding: 80px 0 30px;

		//For Devices larger than 768px
		@include media-breakpoint-up(md) {
			padding-bottom: 100px;
		}

		//For Devices larger than 992px
		@include media-breakpoint-up(lg) {
			padding-bottom: 120px;
		}
		@include media-breakpoint-down(md) {
			padding-bottom: 30px;
		}
	}

	&__logo-box {
		margin-bottom: 30px;
		margin-left: auto;
		margin-right: auto;
		@include media-breakpoint-down(md){
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	&__logo img{
		@include media-breakpoint-only(xs){
			height: 56px;
		}
		@include media-breakpoint-up(sm){
			height: 64px;
		}
	}

	&__section {
		margin-top: 10px;
		margin-bottom: 10px;
		margin-right: 20px;
		//For Devices larger than 768px
		@include media-breakpoint-up(md) {
			margin-top: 0;
		}
		@include media-breakpoint-down(md) {
			text-align: center;
			margin-left: auto;
			margin-right: auto;
			display: flex;
			flex-direction: column;
		}
	}

	&__item {
		&--icon {
			line-height: 18px;
			margin: 0 10px;
		}
	}

	.sb-white-label {
		@include media-breakpoint-up(lg) {
			font-size: 18px;
		}
	}

	&__list {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	&__link {
		font-size: 12px;
		color: transparentize($white, .3);
		padding: 0;
		display: inline;

		@include media-breakpoint-up(lg) {
			font-size: 13px;
		}
	}

	&__credits {
		font-weight: 600;
		font-size: 10px;
		letter-spacing: .05em;
		line-height: 17px;
		text-transform: uppercase;
		color: $white;
		margin-bottom: 12px;

		//For Devices larger than 992px
		@include media-breakpoint-up(lg) {
			max-width: 338px;
		}
	}

	&__social-list {
		list-style: none;
		justify-content: center;
		@include media-breakpoint-up(md) {
			justify-content: flex-end;
		}
	}
}
