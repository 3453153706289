.transaction-table {
    color: $title;

    &:last-child td::after {
        display: none;
    }

    &__subject {
        display: flex;
        align-items: center;
    }

    td {
        padding: 0 !important;
        padding-top: 10px !important;
        padding-bottom: 11px !important;

        &.sell {
            padding-top: 20px !important;
            padding-bottom: 22px !important;
        }

        &:not(:first-child) {
            padding-top: 20px !important;
        }

        &:nth-child(1),
        &:nth-child(2) {
            width: 185px;
        }

        &:nth-child(3),
        &:nth-child(4) {
            width: 120px
        }

        &:not(.sell):nth-child(4) {
            @include media-breakpoint-up(xl) {
                padding-top: 20px !important;
            }
        }

        &.fixed-padding {
            padding-left: 53px !important;
        }

        &:nth-child(5) {
            width: 90px;
        }

        &:first-child {
            padding-left: 20px !important;
        }
    }
}
