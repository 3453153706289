.faq {
    &.container {
        margin-top: 53px;
        margin-bottom: 131px;
        position: relative;

        @include media-breakpoint-between(md, lg) {
            margin-top: 41px;
            margin-bottom: 180px;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 84px;
            margin-bottom: 150px;
        }
    }

    &__title {
        margin-bottom: 20px;
        font-weight: 400;
        //line-height: 64px;

        @include media-breakpoint-down(sm) {
            font-size: 18px;
        }

        @include media-breakpoint-between(md, lg) {
            margin-bottom: 12px;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 30px;
        }
    }

    &__subtitle {
        font-weight: 500;
        line-height: 24px;
        font-size: 14px;
        margin-bottom: 24px;

        @include media-breakpoint-up(md) {
            margin-bottom: 23px;
        }
    }

    &__accordion {
        margin-top: 25px;
        width: 100%;

        @include media-breakpoint-between(md, lg) {
            margin-top: 38px;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 57px;
        }
    }

    &__card {
        border: none;
        border-bottom: 1px solid #EDEDED !important;

        &-header {
            border: none;
            font-size: 16px;
            font-weight: bold;
            color: black;
            padding: 20px 0;
            background: none;
            display: inline-block;
            position: relative;
            //display: flex;
            //justify-content: space-between;
            cursor: pointer;

            &.collapsed {
                .icon--arrow {
                    transform: rotate(0);
                }
            }

            &__title {
                width: calc(100% - 25px);
            }

            &__icon {
                position: absolute;
                top: calc(50% - 12.5px);
                right: 0;
            }
        }

        &-body {
            padding: 0 0 10px;
            font-weight: 500;
            line-height: 24px;
            font-size: 14px;

            @include media-breakpoint-up(md){
                padding-bottom: 20px;
            }
        }
    }

}
