//Utility functions
@function is-map($var){
		@return type-of($var) == 'map';
}

@function webkit-slider-thumb-shadow($i, $color) {
		$val: #{$i}px 0 0 -#{8px} $color;
		@if $i == 1 {
				@for $k from 2 through 1000 {
						$val: #{$val}, webkit-slider-thumb-shadow($k, $color);
				}
		}
		@return $val;
}

//Horizontal separator
.separator {
		box-sizing: border-box;
		background-color: $gray-500;
		margin-top: 10px;
		margin-bottom: 10px;
}

.label {
		&-error {
				color: #EB5757;
		}

		&-default-box-office  {
				color: $body-color !important;
				-webkit-background-clip: unset;
				-webkit-text-fill-color: unset;
		}

		&-gradient {
				background: $button;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
		}

		&-default-color {
			color: $first-button;
		}

		&-warning-color {
			color: $error-text;
		}
}

.font-weight-semibold {
		font-weight: 600 !important;

		&-lg {
				@include media-breakpoint-up(lg) {
						font-weight: 600 !important;
				}
		}
}

.font-weight-light {
		font-weight: 400 !important;
}

.font-weight-medium {
		font-weight: 500 !important;

		&-lg {
				@include media-breakpoint-up(lg) {
						font-weight: 500 !important;
				}
		}
}

.font-weight-bold {
	font-weight: bold !important;
}

.showmore-link {
		font-weight: $font-weight-semi-bold;
		font-size: 12px;
		text-decoration-line: underline;
		color: $first-button !important;

		&:link,
		&:hover,
		&:active,
		&:visited {
				color: $first-button;
		}
}

.close {
		&:focus {
				outline: none;
		}
}

.locked {
		@include media-breakpoint-down(md) {
				position: fixed;
				width: 100%;
		}
}

.modal {
		z-index: 1040;
		&-backdrop {
				z-index: 1030;
		}
}

.font-size-xsmall {
		font-size: 10px !important;
}

.font-size-small {
		font-size: 12px !important;
}

.font-size-medium {
		font-size: 14px !important;
}

.font-size-large {
		font-size: 16px !important;
}

.font-size-xlarge {
		font-size: 18px !important;
}

.font-size-xxlarge {
		font-size: 22px !important;
}

.user-select-none {
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}
