.transaction {
    position: relative;
    padding: 16px 12px;
    margin-bottom: 20px;

    &__wrapper {
        display: flex;

        &__image {
            height: 40px;
            object-fit: contain;
            flex: 0 15%;
            max-width: 40px;

            @include media-breakpoint-up(md) {
                flex: none;
                width: 40px;
                margin-right: 13px;
            }
        }

        &__info {
            flex: 0 100%;
            margin-left: 12px;
        }
    }

    &-table {
        th,
        td {
            position: relative;
            &::after {
                content: ' ';
                position: absolute;
                bottom: 0;
                width: 150%;
                height: 1px;
                background-color: #F2F2F2;
            }
        }

        th {
            padding: 20px 17px 22px 20px;
        }

        td {
            padding: 20px 19px 22px 17px;

            &:last-child {
                &::after{
                    width: calc(100% - 20px);
                }
            }
        }
    }

    &::after {
        content: ' ';
        position: absolute;
        width: 100%;
        height: 1px;
        border-radius: 1px;
        background-color: #F2F2F2;
        bottom: 0;
        left: 50%;
        transform: translate(-50%,0);
    }

    &:last-child {
        margin-bottom: 0;
    }

    &:last-child::after {
        display: none;
    }

    &__title {
        display: flex;
        justify-content: space-between;

        .label:last-child {
            margin-left: 8px;
        }
    }

    &__info {
        margin-top: 0;
    }

    &__bill {
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        margin-top: 15px;

        &__invoice:active,
        &__invoice:hover,
        &__invoice {
            cursor: pointer;
            text-decoration: none;
            color: $first-button;

            @include media-breakpoint-up(md) {
                padding-left: 15px;
                line-height: 0;
                font-size: 0;
                color: transparent;
            }

            // @include media-breakpoint-up(lg) {
            //     line-height: 20px;
            //     font-size: 12px;
            //     text-decoration: underline;
            //     letter-spacing: 0.01em;
            //     color: map_get($theme-colors, "heading-color");
            // }
        }

        &__invoice {
            @include media-breakpoint-between(md, lg) {
                display: flex;
            }

            // img {
            //     @include media-breakpoint-up(lg) {
            //         display: none;
            //     }
            // }
        }

        img {
            padding-right: 7px;

            @include media-breakpoint-up(lg) {
                display: none;
            }
        }
    }
}
