.account-delete-modal {
    @include media-breakpoint-up(lg) {
        width: 540px !important;
    }

    &__content {
        @include media-breakpoint-up(md) {
            height: 384px !important;
        }

        &__buttons {
            padding-bottom: 20px;
        }

        .modal-body {
            @include media-breakpoint-up(md) {
                padding-left: 25px !important;
                padding-right: 25px !important;
            }
        }

        &__form {
            // margin-top: 30px;
            &__container {

                &__subtitle {
                    margin-bottom: 22px;
                }

                &__password {
                    margin-bottom: 20px;

                    @include media-breakpoint-between(md, lg) {
                        max-width: 260px;
                    }

                    @include media-breakpoint-up(lg) {
                        max-width: 245px;
                    }
                }
            }
        }
    }
}
