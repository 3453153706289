.document-carousel {
    // height: 200px;

    // @include media-breakpoint-between(md, lg) {
    //     height: 250px;
    // }

    // @include media-breakpoint-up(lg) {
    //     height: 300px;
    // }

    @include media-breakpoint-up(md) {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    &__swipe-container {
        @include media-breakpoint-between(md, lg) {
            width: 391px;
        }

        @include media-breakpoint-up(lg) {
            width: 470px;
        }
    }

    &__action-button {
        position: absolute;
        top: 110px;
        background: $main-text;
        opacity: 0.3;
        width: 30px;
        height: 30px;
        border-radius: 100px;
        transition: opacity 0.25s ease-in;

        @include media-breakpoint-up(lg) {
            top: 140px;
        }

        &:focus {
            outline: none;
        }

        &.active {
            opacity: 0.7;
            cursor: pointer;
        }

        &__left {
            left: 0;
            transform: scale(-1);
        }

        &__right {
            right: 0
        }
    }

    &__page-controller {
        display: flex;
        justify-content: center;
        align-content: center;
        margin-top: 12px;

        @include media-breakpoint-up(md) {
            margin-bottom: 20px;
        }

        div {
            background: $main-text;
            opacity: 0.3;
            width: 5px;
            height: 5px;
            border-radius: 100px;
            margin-left: 7px;

            &.active {
                opacity: 1.0;
            }
        }

        &:first-child {
            margin-left: 0;
        }
    }
}
