.table-unassociated-ticket {
    &::after {
        @include media-breakpoint-up(lg) {
            width: calc(100% - 110px - 8.3333%);
        }
    }

    &__match {
        @include media-breakpoint-up(lg) {
            padding-left: 15px !important;
            padding-right: 0;
            flex: 0 0 35.4%;
            max-width: 35.4%;
        }
    }

    .cart-ticket__contents__member-card {
        @include media-breakpoint-between(sm, md) {
            padding-right: 365px;
            margin-right: 60px;
        }

        @include media-breakpoint-up(lg) {
            padding-right: 25px;
        }
    }
}