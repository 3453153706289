.sell-transaction {
    position: relative;
    padding: 12px 12px 18px 12px;
    margin-bottom: 20px;

    &::after {
        content: ' ';
        position: absolute;
        width: 100%;
        height: 1px;
        border-radius: 1px;
        background-color: #F2F2F2;
        bottom: 0;
        left: 50%;
        transform: translate(-50%,0);
    }

    &:last-child {
        margin-bottom: 0;
    }

    &:last-child::after {
        display: none;
    }

    &__title {
        display: flex;
        margin-bottom: 12px;

        p:first-child {
            margin-right: 8px;
        }
    }

    &__info {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        &__data .label{
            text-transform: capitalize;
        }
    }

}