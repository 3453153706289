.game {
    margin-top: 20px;

    @include media-breakpoint-up(md) {
        margin-top: 30px;

        &:nth-child(-n + 2) {
            margin-top: 0;
        }
    }

    &__card {
        display: flex;
        flex-direction: column;
        height: 100%;
        border-radius: 5px;
        min-height: 352px;
        transition: all 0.3s ease-in-out;

        @include media-breakpoint-down(lg) {
            background: #FFFFFF;
            mix-blend-mode: normal;
            box-shadow: 0 4px 40px rgba(14, 21, 85, 0.1);
        }

        &:hover {
            .game__card__sell .btn {
                &::after {
                    opacity: 0;
                }
            }

            @include media-breakpoint-up(lg) {
                background: #FFFFFF;
                mix-blend-mode: normal;
                box-shadow: 0 4px 40px rgba(14, 21, 85, 0.1);
            }
        }

        &__image {
            position: relative;

            &::after {
                content: ' ';
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(-50%);
                width: 100%;
                height: 100%;
                background: map_get($colors,"images_gradient");
                border-radius: 5px;
            }

            &__label {
                position: absolute;
                left: 50%;
                transform: translate(-50%);
                bottom: 9px;
                color: white;
                z-index: 1;
                line-height: 12px;
            }

            img {
                border-radius: 5px;

                @include media-breakpoint-up(md) {
                    height: 200px;
                }
            }
        }

        &__title {
            line-height: 20px;
            margin-top: 30px;
            margin-bottom: 0;

            span {
                color: $main-text;
            }

            @include media-breakpoint-up(lg) {
                font-size: 18px;
            }
        }

        &__subtitle {
            line-height: 20px;
            margin-top: 5px;
            margin-bottom: 20px;

            @include media-breakpoint-up(md) {
                line-height: 24px;
            }

            @include media-breakpoint-up(lg) {
                margin-bottom: 23px;
            }
        }

        &__sell {
            margin-top: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 30px;
            padding-right: 30px;
            padding-bottom: 20px;

            &__price {
                margin-bottom: 0;
                line-height: 15px;
                font-weight: bold;
                letter-spacing: 0.01em;
                background: $button;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                @include media-breakpoint-between(md, lg) {
                    line-height: 17px;
                }

                @include media-breakpoint-up(lg) {
                    line-height: 20px;
                    font-weight: 600;
                }
            }

            &__prices-start {
                display: flex;
                flex-direction: column;
                align-items: self-start;

                label {
                    font-size: 10px;
                    line-height: 12px;
                }
            }

            .btn {
                position: relative;
                width: 134px;
                height: 30px;
                padding: 0;
            }
        }
    }
}
