body {
		font-family: $font-family-base;
		font-weight: $font-weight-base;
}

@each $tag,$value in $font-family-definition {
	body #{$tag} {
		font-family: $value;
	}
}

@each $tag,$map in $font-sizing-definition {
		#{$tag}, .#{$tag} {
				// Default style for mobile
				font-size: map_get($map,sm);
				@each $tag,$value in $map {
						@if( $tag == md ) {
								// Get media-query for devices with resolution between small and large
								@include media-breakpoint-between(md,lg){
										font-size: $value;
								}
						} @else if( $tag == lg ) {
								// Get media-query for devices with resolution bigger than large
								@include media-breakpoint-up(lg){
										font-size: $value;
								}
						}
				}
		}
}

h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5 {
		@include media-breakpoint-down(md) {
				font-weight: $font-weight-regular;
		}
}

label , .label {
		font-size: $label-font-size;
		font-weight: $font-weight-medium;
		line-height: 20px;
		margin-bottom: 0;
}

p {
		white-space: pre-line;
}

.label-2 {
		font-size: $label-2-font-size;
		font-weight: $font-weight-medium;
}

// SemiBold White Label
.sb-white-label {
		color: $white;
		font-weight: $font-weight-semi-bold;
}

.font-weight-semibold-lg {
		@include media-breakpoint-up(md) {
				font-weight: $font-weight-semi-bold !important;
		}
}

.text--primary-color {
		color: $title !important;
}

.text--heading-color {
		color: $main-text !important;
}

.text--main-accent {
		color: $main-accent !important;
}

.text--clickable-links {
	background: $clickable-links;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.text--footer {
	color: $text-footer !important;
}

.label{
		&--semi-bold {
				font-weight: $font-weight-semi-bold;
		}

		&--gradient {
				background: $button;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
		}

		&--gradient-default {
			background: $button;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
}
