.balance {
    margin-top: 44px;
    margin-bottom: 100px;

    @include media-breakpoint-between(md, lg){
        margin-top: 50px;
        margin-bottom: 120px;
    }

    @include media-breakpoint-up(lg){
        margin-top: 79px;
        margin-bottom: 150px;
    }

    &__header {
        & .icon-balance {
            width: 35px;
            height: 35px;
            @include media-breakpoint-down(lg) {
                width: 31px;
                height: 31px;
            }
        }

        & .title-balance {
            font-family: 'Montserrat', serif;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: 0.01em;
            color: #1D0F51;
            @include media-breakpoint-down(lg) {
                font-size: 16px;
                line-height: 20px;
            }
            @include media-breakpoint-down(md) {
                font-size: 16px;
                line-height: 20px;
            }
        }

        & .title-funds {
            font-family: 'Montserrat', serif;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 30px;
            letter-spacing: 0.01em;
            color: #1D0F51;
            @include media-breakpoint-down(lg) {
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
            }
        }

        & .available-balance {
            font-family: 'Montserrat', serif;
            font-style: normal;
            font-weight: 600;
            font-size: 42px;
            line-height: 51px;
            background: $button;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            @include media-breakpoint-down(lg) {
                font-size: 40px;
                line-height: 49px;
            }
            @include media-breakpoint-down(md) {
                font-size: 36px;
                line-height: 44px;
            }

        }

        & .funds-coming {
            font-family: 'Montserrat', serif;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 48px;
            letter-spacing: 0.01em;
            @include media-breakpoint-down(lg) {
                font-weight: 600;
                font-size: 24px;
                line-height: 29px;
            }
        }

        & .description-balance {
            font-family: 'Montserrat', serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            @include media-breakpoint-down(lg) {
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
            }
        }

        & .description-funds {
            font-family: 'Montserrat', serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #5E7891;
            max-width: 350px;
        }
    }

    &__total-price {
        margin-top: 40px;

        @include media-breakpoint-up(md) {
            padding-right: 20px;
        }

        .col-12 {
            display: flex;
            justify-content: center;
            flex-direction: column;
        }

        &__info {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            padding-left: 12px;
            padding-right: 12px;

            &__price {
                line-height: normal;
                font-size: 12px;
                letter-spacing: 0.01em;
                background: $button;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                @include media-breakpoint-between(md, lg) {
                    font-size: 14px;
                }

                @include media-breakpoint-up(lg) {
                    font-size: 16px;
                }
            }
        }

        &__payout {
            position: relative;
            width: calc(100% - 24px);
            height: 51px;

            // @include media-breakpoint-down(sm) {
                margin-left: auto;
                margin-right: auto;
            // }

            @include media-breakpoint-up(md) {
                width: 100%;
                max-width: 255px;
            }
        }
    }

    &__payout-type {
        margin-top: 80px;

        &__header {
            display: flex;
            align-items: baseline;

            &__title {
                padding-right: 9px;
                border-right: 1px solid #D0D0D0;
                color: $title;
                font-weight: 500;
                margin: 0;
            }

            &__edit {
                color: $first-button;
                cursor: pointer;
                margin-left: 9px;
            }
        }

        &__method {
            margin: 26px 0 0;
            font-weight: normal;
            line-height: normal;
            color: map_get($theme-colors, "paragraph-color");
        }
    }

    &__payout-data {
        margin-top: 80px;

        @include media-breakpoint-up(md) {
            margin-top: 100px;
        }

        &__info-block {
            display: flex;
            flex-direction: column;
            margin-top: 28px;

            &__label {
                font-size: 12px;

                &--dark-blue {
                    color: map_get($theme-colors, "heading-color");
                }

                &--light-blue {
                    color: map_get($theme-colors, "paragraph-color");
                }

                &:first-child {
                    margin-bottom: 2px;
                }

                @include media-breakpoint-between(md, lg) {
                    font-size: 14px;
                }

                @include media-breakpoint-up(lg) {
                    font-size: 16px;
                }
            }
        }


        &__load-document {
            position: relative;
            align-items: center;
            justify-content: center;
            margin-top: 28px;

            height: 31px;
            padding: 0 30px;
            max-width: 295px;
        }

        &__document {
            cursor: pointer;
            margin-top: 32px;
            width: 100%;
            border: 1px solid rgba(29, 15, 81, 0.1);
            border-radius: 8px;
            height: 224px;
            object-fit: scale-down;

            @include media-breakpoint-between(md, lg) {
                width: calc(100% - 15px);
            }

            @include media-breakpoint-up(md) {
                height: 166px;
            }
        }

        &__document-buttons {
            display: flex;
            margin-top: 15px;

            &__extend {
                display: flex;
                align-items: center;
                line-height: 24px;
                font-size: 14px;
                margin-right: 37px;
            }

            &__remove {
                display: flex;
                align-items: center;
                color: #F23254 !important;
                line-height: 24px;
                font-size: 14px;
            }
        }
    }
}

.balance-payout-modal {
    justify-content: center;
    margin: auto;
    @include media-breakpoint-up(lg){
        width: 85%;
    }

    & .header-info {
        font-family: 'Montserrat', serif;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 170.66%;
        color: #5E7891;
        & .blue {
            color: #0091D2;
        }
        & .red {
            color: #BF4A55;
        }
    }
    & .icon-balance {
        width: 35px;
        height: 34px;
        @include media-breakpoint-down(md){
            width: 49px;
            height: 48px;
        }
    }
    & .icon-warning {
        width: 20px;
        height: 20px;
    }
    & .available-balance-text {
        font-family: 'Montserrat', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 170.66%;
        color: #1D0F51;
    }
    & .available-balance-gradient {
        font-family: 'Montserrat', serif;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 109.71%;
        letter-spacing: 0.01em;
        background: linear-gradient(262.12deg, #88BE4B 2.46%, #0091D2 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    & .tax-info {
        font-family: 'Montserrat', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 170.66%;
        color: #5E7891;
    }
    & .divider {
        width: 50%;
        height: 1px;
        margin: auto;
        background: rgba(196, 196, 196, 0.5);
        border-radius: 14px;
    }
    & .transfer-info {
        font-family: 'Montserrat', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 170.66%;
        text-align: justify;
        color: #5E7891;
    }
    & .payout-data-shortcut {
        font-family: 'Montserrat', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 170.66%;
        color: #5E7891;
        word-wrap: break-word;
        span {
            text-decoration: underline;
            cursor: pointer;
        }
        span:hover{
            color: #475c6e;
        }
    }
}

.balance-details-modal {

    justify-content: center;
    margin: auto;
    @include media-breakpoint-up(lg){
        width: 85%;
    }
    & .ticket-icon {
        width: 45px;
        height: 44px;
    }
    & .balance-modal-header {
        font-family: 'Montserrat', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #1D0F51;
    }
    & .info-header {
        font-family: 'Montserrat', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #1D0F51;
    }
    & .info-content {
        font-family: 'Montserrat', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: $main-text;
        & .blue {
            color: #0091D2;
        }
        & .green {
            color: #70BE4B;
        }
        & .red {
            color: #BF4A55;
        }
    }
    & .completed {
        color: #70BE4B;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
    }
    & .pending {
        color: #0091D2;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
    }
    & .failed {
        color: #BF4A55;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
    }

}
