.quantities-filter {
    display: flex;
    align-items: center;

    &__less,
    &__plus {
        border-radius: 100px;
        background: transparentize($color: $title, $amount: 0.9);
        width: 14px;
        height: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    &__current-quantity {
        min-width: 37px;
        text-align: center;
        font-weight: 500;
        font-size: 18px;
        line-height: normal;
        letter-spacing: 0.01em;
        margin-bottom: 0;

        &__fixed {
            text-align: left;
            padding-left: 29px;
        }
    }
}
