.filter {

		&__body {
				&--sector {
						padding-top: 0;
						padding-bottom: 20px;

						@include media-breakpoint-between(md,lg) {
								padding-left: 15px;
								padding-right: 15px;
						}

						&__button {
								&--show-more {
										position: relative;
										width: 116px;
										height: 34px;
										padding: 0;

										&__text {
												font-weight: 500;
												font-size: 14px;
												letter-spacing: initial;
												text-transform: initial;
										}
								}
						}

				}

				&__info {
						flex-direction: row;
						display: flex;
						align-items: center;
						justify-content: space-between;
						padding-bottom: 13px;

						&--sector {
								padding-top: 20px;
								padding-bottom: 10px;
								justify-content: initial;

								@include media-breakpoint-up(lg) {
										padding-top: 0;
										padding-bottom: 0;
								}

						}

						&__label {
								font-weight: 600;
								color: $title;

								&--quantity {
										font-weight: $font-weight-semi-bold;
										font-size: 16px;
										color: $body-color;
								}

						}

						&__quantity {
								&--expanded {
										@include media-breakpoint-down(sm) {
												display: flex;
												align-items: center;
										}
								}
						}

						&__icon {

								&--question {
										//display: flex;
										display: none;
										width: 16px;
										height: 16px;
										margin-left: 10px;
										background: url("../../img/icon/question-dotted.svg") no-repeat center;
										background-size: contain;
								}

						}

				}

				&__element {

						&--sector {
								user-select: none;
								display: inline-flex;
								//padding: 8px 20px;
								padding: 5px 20px;
								line-height: 24px;
								margin: 0 10px 10px 0;
								font-size: 14px;
								color: #BDBDBD;
								background: #F5F6F7;
								border-radius: 605px;
								cursor: pointer;

								&.active {
										color: set-button-text-color($title);
										background: $title;
										position: relative;
										padding-right: 36px;

										&::after{
												position: absolute;
												top: 50%;
												right: 20px;
												transform: translate(0,-50%);
												height: 7px;
												width: 7px;
												content: ' ';
												background-color: set-button-text-color($title);
												mask: url('../../img/icon/close-red.svg') no-repeat center;
												mask-size: 100%;
										}

								}

						}

						&--typology {
								.form-check {
										margin-bottom: 20px;

										&:last-child {
												margin-bottom: 0;
										}

								}

						}

						&--price {
								display: flex;
								flex-wrap: wrap;

								@include media-breakpoint-up(lg) {
										flex-direction: column;
								}

								.form-check {
										width: 50%;
										margin-bottom: 20px;

										@include media-breakpoint-down(sm) {
												width: 100%;
										}

										@include media-breakpoint-between(md,lg) {
												width: 125px;
										}

										&:last-child {
												margin-bottom: 10px;
										}

								}

						}


						&--general-check {
							display: flex;
							flex-wrap: wrap;
							align-items: center;

							@include media-breakpoint-up(lg) {
									flex-direction: column;
							}

							.form-check {
									width: 150px;
									margin-bottom: 20px;

									@include media-breakpoint-between(md,lg) {
											width: 155px;
									}

									&:last-child {
											margin-bottom: 10px;
									}

							}

						}

				}

		}

		&__head {
				background-color: $white;
				position: relative;
				z-index: 107;

				@include media-breakpoint-up(lg) {
						margin-bottom: 20px;
				}

				&::after {
						content: ' ';
						position: absolute;
						bottom: 0;
						left: 0;
						height: 1px;
						width: 100%;
						background-color: #F2F2F2;
				}

				&__label {
						margin-bottom: 10px;
				}

				&--category {
					@include media-breakpoint-up(lg) {
						margin-bottom: 10px;
					}
				}
		}

		&__label {
				font-weight: 600;
				font-size: 10px;
				text-transform: uppercase;
				color: $title;

				&--sector {
						margin-left: 30px;
				}

				&--position {
						margin-right: 364px;

						@include media-breakpoint-up(xl) {
								margin-right: 463px;
						}

				}

		}

}
