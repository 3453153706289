.product {
    margin-top: 20px;

    @include media-breakpoint-down(md) {
        &:nth-child(-n + 2) {
            margin-top: 0;
        }
    }

    @include media-breakpoint-up(md) {
        &:nth-child(-n + 3) {
            margin-top: 0;
        }
    }

    &__card {
        display: flex;
        flex-direction: column;
        height: 100%;
        border-radius: 5px;
        min-height: 310px;

        @include media-breakpoint-between(md, lg) {
            min-height: 350px;
        }

        @include media-breakpoint-up(lg) {
            min-height: 360px;
        }

        &:hover {
            background: #FFFFFF;
            mix-blend-mode: normal;
            box-shadow: 0 4px 40px rgba(14, 21, 85, 0.1);

            @include media-breakpoint-up(lg) {
                background: #FFFFFF;
                mix-blend-mode: normal;
                box-shadow: 0 4px 40px rgba(14, 21, 85, 0.1);
            }
        }

        &__image {
            position: relative;

            img {
                object-fit: scale-down;
                padding-top: 10px;
                padding-left: 10px;
                padding-right: 10px;
                height: 150px;

                @include media-breakpoint-between(md, lg) {
                    padding-top: 20px;
                    padding-right: 31px;
                    padding-left: 31px;
                    height: 170px;
                }

                @include media-breakpoint-up(md) {
                    height: 180px;
                    padding-top: 30px;
                    padding-right: 40px;
                    padding-left: 40px;
                }
            }
        }

        &__title {
            padding-left: 10px;
            padding-right: 10px;
            margin-bottom: 3px;
            font-weight: 600;
            line-height: normal;
            text-align: center;
            letter-spacing: 0.01em;

            @include media-breakpoint-between(md, lg) {
                margin-bottom: 13px;
                font-size: 16px;
            }

            @include media-breakpoint-up(lg) {
                margin-bottom: 10px;
                font-size: 18px;
            }
        }

        &__subtitle {
            margin-bottom: 7px;
            margin-top: 10px;
            font-size: 10px;
            line-height: normal;
            text-align: center;
            text-transform: uppercase;

            @include media-breakpoint-up(lg) {
                margin-top: 16px;
            }
        }

        &__sell {
            margin-top: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 20px;

            @include media-breakpoint-up(md) {
                padding-bottom: 30px;
            }

            &__price {
                margin: 0;
                font-size: 16px;
                line-height: normal;
                text-align: center;
                letter-spacing: 0.01em;
                font-weight: 600;
                background: $button;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                @include media-breakpoint-up(md) {
                    line-height: 17px;
                }
            }

            &__btn {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                max-width: 138px;
                height: 30px;
                padding: 0;

                @include media-breakpoint-up(md) {
                    max-width: 167px;
                }
            }
        }
    }

}
