.bundle {
    position: relative;
    margin-bottom: 40px;
    mix-blend-mode: normal;

    filter: drop-shadow(0 2px 8px rgba(14, 21, 85, 0.12));

    border-radius: 5px;
    transition-property: bottom;
    transition-duration: .1s;
    transition-timing-function: ease-in;
    bottom: 0;

    display: flex;
    flex-direction: column;

    padding: 0;
    background-color: transparent;

    @include media-breakpoint-up(md) {
        margin-top: 0;
        height: 290px;
    }

    &:before, &:after {
        content: '';
        position: absolute;
        height: 10px;
        border-radius: 5px 5px 0 0;
    }

    &:before {
        width: calc(100% - 48px);
        left:28px;
        top: -10px;
        background-color: rgba($main-accent, .1);
        z-index: 0;
        transition: .1s top;
    }

    &:after {
        width: calc(100% - 16px);
        left:8px;
        top: -5px;
        background-color: rgba($main-accent, .3);
        z-index: 1;
        transition: .1s top;
    }

    &:hover {
        bottom: 5px;

        &:before {
            top: -4px;
        }
        &:after {
            top: -2px;
        }

        @include media-breakpoint-up(md) {
            bottom: 10px;
        }
    }

    &__title {
        font-size: 16px;
        margin-bottom: 24px;

        @include media-breakpoint-up(md) {
            height: 40px;
            margin-bottom: 4px;
        }
    }

    &__top, &__bottom {
        background-color: white;
    }

    &__top {
        display: flex;
        flex-direction: column;
        padding: 28px 24px 23px;
        border-radius: 5px 5px 2px 2px;
        border-top: solid 5px rgba($main-accent, .5);
        position: relative;
        overflow: hidden;
        z-index: 2;

        .bundle--feature &{
            padding: 17px 24px 12px;
        }

        @include media-breakpoint-up(md) {
            padding: 27px 12px 23px;
        }

        @include media-breakpoint-up(lg) {
            padding: 27px 24px 23px;
        }

        &:after {
            content: attr(title);
            position: absolute;
            padding-right: 12px;
            padding-bottom: 6px;
            top: -13px;
            right: -7px;
            height: 39px;
            width: 39px;
            border-radius: 100%;
            background-color: rgba($main-accent, .5);
            color: $white;
            font-size: 16px;
            letter-spacing: 0.01em;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
        }
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        padding: 4px 24px 18px;
        border-radius: 2px 2px 5px 5px;

        @include media-breakpoint-up(md) {
            flex-direction: column;
            padding: 12px 12px 40px;
        }

        @include media-breakpoint-up(lg) {
            padding: 12px 14px 40px;
            justify-content: center;
            align-items: center;
        }

        @include media-breakpoint-up(xl) {
            padding: 12px 24px 40px;
        }
    }

    &__center {
        width: 100%;
        height: 25px;
        display: inline-flex;
        position: relative;
    }


    &__center__half {
        width: 50%;
        height: 100%;
        position: relative;
        overflow: hidden;

        &-first:before, &-last:after {
            content: "";
            width: 25px;
            height: 25px;
            position: absolute;
            top:0;
            border-radius: 100%;
            box-shadow: 0 0 0 9999px #fff;
            z-index: -1;
        }

        &-first:before {
            left: -12.5px;
        }
        &-last:after {
            right: -12.5px;
        }
    }

    &__dashed-line {
        position: absolute;
        top: calc(50% - 2.5px);
        width: calc(100% - 50px);
        left: 25px;
        opacity: 0.1;
    }

    &__shop,
    &__info {
        margin-right: 15px;
        margin-left: 15px;
    }

    &__info {
        &__position {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            & .label span {
                word-break: break-all;
                display: block;
                line-height: 16px;
            }
        }
    }

    &__shop {
        margin-top: 34px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 350px) {
            margin-top: 5px;
            align-items: flex-end;
        }


        @include media-breakpoint-up(md) {
            flex-direction: column;
            margin-top: auto;
        }

        &__price-section {
            display: flex;
            flex-direction: column;

            @media (max-width: 350px) {
                margin-bottom: 29px;
            }

            @include media-breakpoint-up(md){
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                align-items: baseline;
                margin-bottom: 10px;
            }

            &__single-price {
                font-size: 10px;
                color: #5E7891;
                opacity: 0.5;
                margin-bottom: -5px;
            }
        }

        &__button {
            position: relative;
            font-weight: 600;
            font-size: 12px;
            letter-spacing: 0.05em;
            color: white;
            width: 200px;
            height: 31px;
            line-height: 0;

            box-shadow: none !important;

            @include media-breakpoint-between(md,lg) {
                width: 186px;
            }

            @include media-breakpoint-up(lg) {
                width: 100%;
                //max-width: 201px;
                padding: 0;
            }

            .btn__text-gradient {
                font-size: 11px;
            }
        }
    }

    &__gradientLabel {
        display: inline;
        background: $button;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        &--feature {
            font-size: 12px;
            text-transform: uppercase;
            margin-bottom: 4px;
            letter-spacing: 0.05em;
        }

        &--price {
            white-space: nowrap;
            line-height: 23px;
            font-weight: bold;
            font-size: 14px;
            margin: 0;
            background: $button;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            @include media-breakpoint-up(md) {
                line-height: 24px;
            }
        }
    }
}
