.plp {
    margin-top: 50px;
    margin-bottom: 100px;
    min-height: 100vh;

    @include media-breakpoint-up(md) {
        margin-top: 72px;
        margin-bottom: 40px;
    }

    @include media-breakpoint-up(lg) {
        margin-top: 100px;
        margin-bottom: 80px;

        &::before {
            content: ' ';
            z-index: -1;
            position: absolute;
            background: url("data:image/svg+xml;utf8,%3Csvg%20width%3D%22288%22%20height%3D%22387%22%20viewBox%3D%220%200%20288%20387%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20opacity%3D%220.23%22%20d%3D%22M62.5661%20341.469C-6.08453%20301.834%20-19.6417%20196.792%2032.2854%20106.851C84.2125%2016.9111%20181.96%20-23.8691%20250.611%2015.7664C319.261%2055.4019%20279.728%20242.078%20227.801%20332.018C175.874%20421.958%20131.217%20381.105%2062.5661%20341.469Z%22%20fill%3D%22url(%23paint0_linear)%22%2F%3E%0A%3Cdefs%3E%0A%3ClinearGradient%20id%3D%22paint0_linear%22%20x1%3D%22186.869%22%20y1%3D%22413.236%22%20x2%3D%22329.013%22%20y2%3D%2216.6547%22%20gradientUnits%3D%22userSpaceOnUse%22%3E%0A%3Cstop%20offset%3D%220.0246272%22%20stop-color%3D%22#{$second-button}%22%2F%3E%0A%3Cstop%20offset%3D%221%22%20stop-color%3D%22#{$first-button}%22%2F%3E%0A%3C%2FlinearGradient%3E%0A%3C%2Fdefs%3E%0A%3C%2Fsvg%3E%0A") no-repeat;
            height: 400px;
            width: 500px;
            left: 0;
            top: 740px;
            transform: translate(-70%) rotate(120deg);
        }

        &::after {
            content: ' ';
            z-index: -1;
            position: absolute;
            background-image:
               url("data:image/svg+xml,%3Csvg width='696' height='538' viewBox='0 0 696 538' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.1' d='M584.808 315.092C584.808 437.975 454.075 537.592 292.808 537.592C131.54 537.592 0.807618 437.975 0.807618 315.092C0.807617 192.208 282.54 100.592 443.808 100.592C605.075 100.592 584.808 192.208 584.808 315.092Z' fill='url(%23paint0_linear)'/%3E%3Cpath opacity='0.5' d='M693.808 215.092C693.808 276.155 661.328 331.508 608.677 371.628C556.024 411.748 483.242 436.592 402.808 436.592C322.373 436.592 249.591 411.748 196.939 371.628C144.287 331.508 111.808 276.155 111.808 215.092C111.808 184.775 129.185 156.258 158.015 130.48C186.835 104.711 226.982 81.7903 272.222 62.6817C362.704 24.4631 473.322 1.59177 553.808 1.59177C594.046 1.59177 622.84 7.30943 643.422 17.7193C663.966 28.1097 676.39 43.2057 683.853 62.1274C691.332 81.091 693.836 103.919 694.455 129.759C694.817 144.888 694.534 160.974 694.237 177.883C694.026 189.887 693.808 202.306 693.808 215.092Z' stroke='url(%23paint1_linear)' stroke-width='2'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='584.808' y1='92.5916' x2='-58.8004' y2='209.535' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.0246272' stop-color='#{$second-button}'/%3E%3Cstop offset='1' stop-color='#{$first-button}'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear' x1='694.808' y1='-7.40835' x2='51.1996' y2='109.535' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.0246272' stop-color='#{$second-button}'/%3E%3Cstop offset='1' stop-color='#{$first-button}'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
            background-repeat:
               no-repeat;
            width: 220px;
            height: 530px;
            top: 455px;
            right: 0;
        }
    }

    &--sold-out {
        margin-bottom: 120px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 180px;
            &::before {
                content: ' ';
                position: absolute;
                background: url("data:image/svg+xml;utf8,%3Csvg%20width%3D%22288%22%20height%3D%22387%22%20viewBox%3D%220%200%20288%20387%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20opacity%3D%220.23%22%20d%3D%22M62.5661%20341.469C-6.08453%20301.834%20-19.6417%20196.792%2032.2854%20106.851C84.2125%2016.9111%20181.96%20-23.8691%20250.611%2015.7664C319.261%2055.4019%20279.728%20242.078%20227.801%20332.018C175.874%20421.958%20131.217%20381.105%2062.5661%20341.469Z%22%20fill%3D%22url(%23paint0_linear)%22%2F%3E%0A%3Cdefs%3E%0A%3ClinearGradient%20id%3D%22paint0_linear%22%20x1%3D%22186.869%22%20y1%3D%22413.236%22%20x2%3D%22329.013%22%20y2%3D%2216.6547%22%20gradientUnits%3D%22userSpaceOnUse%22%3E%0A%3Cstop%20offset%3D%220.0246272%22%20stop-color%3D%22#{$second-button}%22%2F%3E%0A%3Cstop%20offset%3D%221%22%20stop-color%3D%22#{$first-button}%22%2F%3E%0A%3C%2FlinearGradient%3E%0A%3C%2Fdefs%3E%0A%3C%2Fsvg%3E%0A") no-repeat;
                height: 400px;
                width: 500px;
                left: 0;
                top: 600px;
                transform: translate(-70%) rotate(120deg);
            }

            &::after {
                content: ' ';
                position: absolute;
                background-image:
                   url("data:image/svg+xml,%3Csvg width='696' height='538' viewBox='0 0 696 538' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.1' d='M584.808 315.092C584.808 437.975 454.075 537.592 292.808 537.592C131.54 537.592 0.807618 437.975 0.807618 315.092C0.807617 192.208 282.54 100.592 443.808 100.592C605.075 100.592 584.808 192.208 584.808 315.092Z' fill='url(%23paint0_linear)'/%3E%3Cpath opacity='0.5' d='M693.808 215.092C693.808 276.155 661.328 331.508 608.677 371.628C556.024 411.748 483.242 436.592 402.808 436.592C322.373 436.592 249.591 411.748 196.939 371.628C144.287 331.508 111.808 276.155 111.808 215.092C111.808 184.775 129.185 156.258 158.015 130.48C186.835 104.711 226.982 81.7903 272.222 62.6817C362.704 24.4631 473.322 1.59177 553.808 1.59177C594.046 1.59177 622.84 7.30943 643.422 17.7193C663.966 28.1097 676.39 43.2057 683.853 62.1274C691.332 81.091 693.836 103.919 694.455 129.759C694.817 144.888 694.534 160.974 694.237 177.883C694.026 189.887 693.808 202.306 693.808 215.092Z' stroke='url(%23paint1_linear)' stroke-width='2'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='584.808' y1='92.5916' x2='-58.8004' y2='209.535' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.0246272' stop-color='#{$second-button}'/%3E%3Cstop offset='1' stop-color='#{$first-button}'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear' x1='694.808' y1='-7.40835' x2='51.1996' y2='109.535' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.0246272' stop-color='#{$second-button}'/%3E%3Cstop offset='1' stop-color='#{$first-button}'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
                background-repeat:
                   no-repeat;
                width: 220px;
                height: 530px;
                top: 210px;
                right: 0;
            }
        }
    }

    &__contents {
        @include media-breakpoint-up(lg) {
            margin-bottom: 30vh;
        }
    }

    &__header {
        &__title{
            margin-bottom: 20px;
            h2 {
                margin: 0;
                @include media-breakpoint-up(lg){
                    font-size: 34px;
                }
            }
        }

        &__subtitle {
            p {
                font-size: 13px;
                margin: 0;

                span {
                    color: map_get($theme-colors, "heading-color");
                }
            }
        }
    }

    &__contents {
        margin-top: 54px;

        @include media-breakpoint-up(lg) {
            margin-top: 97px;
        }

        &--sold-out {
            margin-top: 120px;

            @include media-breakpoint-up(md) {
                margin-top: 160px;
            }
        }
    }

    &__filters {
        position: relative;

        @include media-breakpoint-up(md) {
            margin-bottom: 20px;
        }

        &__col {
            display: flex;
            padding-bottom: 5px;

            @include media-breakpoint-up(md) {
                padding-bottom: 7px;
            }

            @include media-breakpoint-up(lg) {
                flex-direction: column;
            }

            &::after {
                content: ' ';
                position: absolute;
                width: calc(100% - 30px);
                height: 1px;
                bottom: 0;
                left: 50%;
                transform: translate(-50%);
                background: #F2F2F2;

                @include media-breakpoint-up(lg) {
                    display: none;
                }
            }

            &__group {
                display: flex;
                align-items: center;
                cursor: pointer;

                //&:last-child {
                //    margin-left: 40px;
                //}

                &__label {
                    font-size: 14px;
                    line-height: normal;
                    color: $title;
                    text-transform: capitalize;
                    font-weight: 600;
                    cursor: pointer;
                }

                &__icon {
                    width: 5px;
                    height: 9px;
                    margin-left: 10px;
                }
            }

            &__header {
                display: flex;

                @include media-breakpoint-down(sm) {
                    cursor: pointer;
                }

                @include media-breakpoint-up(lg) {
                    border-bottom: 1px solid #F2F2F2;
                    padding-bottom: 4px;
                }

                &__label {
                    font-size: 14px;
                    line-height: 24px;
                    color: map_get($theme-colors, "heading-color");
                    text-transform: uppercase;
                    font-weight: 600;

                    @include media-breakpoint-down(sm) {
                        cursor: pointer;
                    }

                    @include media-breakpoint-up(md) {
                        line-height: normal;
                    }
                }

                &__icon {
                    margin-left: 5px;
                }
            }
        }

        &__body {
            padding-top: 30px;

            &__showmore {
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                padding-top: 8px;
                margin-bottom: 30px;
            }

            .filter__body__info {
                padding-bottom: 10px;
            }

            .filter__body__element {
                padding-bottom: 10px;
            }
        }
    }
}
