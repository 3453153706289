// Mixins utilities

// Make columns with offset
@mixin make-columns($col-size, $offset-size: 0) {
		// Bootstrap default prepare the columns
		@include make-col-ready();
		@if($offset-size != 0){
				// Bootstrap default add the offset
				@include make-col-offset($offset-size);
		}
		// Bootstrap default add the columns
		@include make-col($col-size);
}

// Add hover transition and style
// to the buttons
@mixin button-hover {
		&:after {
				content: '';
				border-radius: 100px;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background: $black;
				opacity: 0;
				mix-blend-mode: overlay;
				transition: .3s opacity;
		}

		&:hover {
				&:after {
						opacity: .5;
				}
		}
}

// Make vertical separator
// $position must be before or after
// The root parent must be position relative
@mixin make-vertical-sep($position, $color, $height, $width: 1px, $radius: 0, $left-offset: -10px, $top-offset: 0){
		&::#{$position} {
				content : "";
				position: absolute;
				transform: translate(-50%, 5%);
				left: $left-offset;
				top: $top-offset;
				width: $width;
				height: $height;
				background: $color;
				border-radius: $radius;

				@content
		}
}

// Make horizontal separator
// $position must be before or after
// The root parent must be position relative
@mixin make-horizontal-sep($position, $height, $width: 1px, $radius: 0){
		&::#{$position} {
				content : "";
				position: absolute;
				display: block;
				left: 50%;
				transform: translate(-50%);
				width: $width;
				height: $height;
				background: $lines-and-box;
				border-radius: $radius;

				@content
		}
}

@mixin preloader-div-setup {
		border-radius: 50%;
		padding: 8px;
		border: 2px solid transparent;
		animation: rotate linear 3.5s infinite;
}

@function encodecolor($string) {
		$hex: str-slice(inspect($string),2,-1);
		$string: '%23' + $hex;
		@return $string;
}

@function number($string) {
		// Matrices
		$strings: '0' '1' '2' '3' '4' '5' '6' '7' '8' '9';
		$numbers: 0 1 2 3 4 5 6 7 8 9;

		// Result
		$result: 0;

		// Looping through all characters
		@for $i from 1 through str-length($string) {
				$character: str-slice($string, $i, $i);
				$index: index($strings, $character);

				@if not $index {
						@warn "Unknown character `#{$character}`.";
						@return false;
				}

				$number: nth($numbers, $index);
				$result: $result * 10 + $number;
		}

		@return $result;
}

@function hex-to-dec($hex) {
		$hex: to_upper_case($hex);
		$value: 0;
		@if $hex == 'A' {
				$value: 10;
		} @else if $hex == 'B' {
				$value: 11;
		} @else if $hex == 'C' {
				$value: 12;
		} @else if $hex == 'D' {
				$value: 13;
		} @else if $hex == 'E' {
				$value: 14;
		} @else if $hex == 'F' {
				$value: 15;
		} @else if number($hex) < 10 {
				$value: number($hex);
		}
		@return $value;
}

@function toRGB($color) {
		@if(type_of($color) == "string"){
				$support_red: 0;
				$support_green: 0;
				$support_blue: 0;

				$number: str-slice($color, 2, 2);
				$support_red: $support_red + (hex-to-dec($number) * 16);

				$number: str-slice($color, 3, 3);
				$support_red: $support_red + hex-to-dec($number);

				$number: str-slice($color, 4, 4);
				$support_green: $support_green + (hex-to-dec($number) * 16);

				$number: str-slice($color, 5, 5);
				$support_green: $support_green + hex-to-dec($number);

				$number: str-slice($color, 6, 6);
				$support_blue: $support_blue + (hex-to-dec($number) * 16);

				$number: str-slice($color, 7, 7);
				$support_blue: $support_blue + hex-to-dec($number);

				@return rgb($support_red, $support_green, $support_blue);
		} @else {
				@return $color;
		}
}

@function set-button-text-color($color) {
		@if (lightness( $color ) > 40) {
				@return black;
		}
		@else {
				@return white;
		}
}
