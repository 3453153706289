.information {
		&.container {
				margin-top: 53px;
				margin-bottom: 75px;

				@include media-breakpoint-between(md,lg) {
						margin-top: 41px;
						margin-bottom: 115px;
				}

				@include media-breakpoint-up(lg) {
						margin-top: 84px;
						margin-bottom: 70px;
				}
		}

		&__title {
			font-weight: 400;
			white-space: pre-wrap;
				&-green {
					color: #82C44C !important;
				}
				&-red {
					color: $error-text !important;
				}

				margin-bottom: 20px;
				//line-height: 64px;

				@include media-breakpoint-down(md){
					line-height: 40px;
						font-size: 32px;
				}

				@include media-breakpoint-between(md,lg) {
					line-height: 40px;
						font-size: 32px;
				}

				@include media-breakpoint-up(lg) {
					font-size: 48px;
					margin-bottom: 30px;
				}
		}

		&__subtitle {
				font-weight: 500;
				line-height: 24px;
				font-size: 14px;
				margin-bottom: 45px;

				@include media-breakpoint-up(md) {
						margin-bottom: 80px;
				}
		}

		&__text-block {
				margin-top: 25px;
				margin-bottom: 25px;

				@include media-breakpoint-up(lg) {
						margin-top: 20px;
						margin-bottom: 20px;
				}

				&__title {
						font-weight: 600;
						letter-spacing: 0.01em;
						margin-bottom: 10px;
						color: #000000;

						@include media-breakpoint-up(lg) {
								margin-bottom: 15px;
						}
				}

				&__paragraph {
						font-style: normal;
						font-weight: 500;
						line-height: 24px;
						margin-bottom: 0;
				}
		}
}
