.member-card {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    width: 190px;

    height: 96px !important;
    margin-right: 25px;
    background: #FFFFFF;
    mix-blend-mode: normal;
    box-shadow: 0px 4px 40px rgba(14, 21, 85, 0.1);
    border-radius: 8px;
    padding: 17px 12px 9px 12px;
    position: relative;

    &__mobile {
        margin: 20px auto;
        height: 120px !important;
        width: 280px !important;
    }

    &__colors {
        content: ' ';
        position: absolute;
        mix-blend-mode: normal;
        border-radius: 8px 8px 0px 0px;

        top: 0;
        width: 100%;
        height: 10px;
        left: 50%;
        transform: translate(-50%);
    }

    &__personal-info {
        margin-bottom: 16px;

        &__owner {
            font-weight: 600;
            line-height: 24px;
            color: $headings-color;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

    }

    &__general-info {
        display: flex;
        justify-content: space-between;
        margin-top: auto;

        &__associate {
            font-weight: 600;
            line-height: 12px;
            background: $button;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

        }
    }
}
