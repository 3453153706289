.PDP {

		&.container {
				padding-top: 56px;
				padding-bottom: 36px;

				@include media-breakpoint-up(lg){
						padding-top: 87px;
						padding-bottom: 102px;
				}
		}

		&__announcements__tag {
				width: auto;
				padding: 0 10px;
				box-shadow: none;
				text-transform: uppercase;
				margin-bottom: 26px;
		}

		&__header {
				margin-bottom: 46px;

				@include media-breakpoint-up(lg) {
						margin-bottom: 80px;
				}

				&__title {
						margin-bottom: 23px;

						@include media-breakpoint-up(lg) {
								margin-bottom: 20px;
						}

					&__versus {
							font-size: 12px;
							font-weight: $font-weight-regular;
							color: #666666;

							@include media-breakpoint-between(md,lg) {
									font-size: 18px;
							}

							@include media-breakpoint-up(lg) {
									font-size: 36px;
							}
					}
				}

				&__subtitle {
						font-size: 12px;
						margin-bottom: 0;

						&__datetime {
								text-transform: capitalize;
						}

						@include media-breakpoint-between(md,lg) {
								font-size: 14px;
						}

						@include media-breakpoint-up(lg) {
								font-size: 15px;
						}
				}
		}

		&__sold-out {
			margin-bottom: 120px;

			@include media-breakpoint-up(md) {
				margin-bottom: 200px;
			}

			div {
				margin-left: 0;
			}

			.PDP__header {
				margin-bottom: 80px;

				@include media-breakpoint-up(md) {
					margin-bottom: 160px;
				}
			}
		}

		&__merchandising {
			margin-top: 80px;
		}
}
