.account-change-billing-data-modal {
    @include media-breakpoint-up(lg) {
        width: 730px;
    }

    &__content {
        border-radius: 8px;
        height: unset;

        .modal-body {
            padding: 0;

            @include media-breakpoint-up(lg) {
                padding-left: 20px;
                padding-right: 20px;
            }

            .container{
                @include media-breakpoint-up(md) {
                    height: unset;
                }
            }
        }

        &__form {
            margin-top: 25px;

            @include media-breakpoint-up(md) {
                margin-top: 46px;
            }

            div:nth-last-of-type(-n + 3) {
                .account-change-billing-data-modal__content__form__group {
                    @include media-breakpoint-between(md, lg) {
                        margin-bottom: 30px;
                    }
                }
            }

            div:nth-last-of-type(-n + 2) {
                .account-change-billing-data-modal__content__form__group {
                    @include media-breakpoint-up(lg) {
                        margin-bottom: 30px;
                    }
                }
            }

            &__group {
                margin-bottom: 23px;
            }
        }
    }
}
