.icon {
		display: inline-block;
		height: 21px;
		width: 22px;
		margin-right: 10px;

		//For devices larger than 992px
		@include media-breakpoint-up(lg){
				height: 25px;
				width: 25px;
		}

		&--password {
				transition: .3s transform;
				width: 20px;
				height: 20px;
				right: 5px;
				z-index: 10;
				top: 6px;
				position: absolute;
				cursor: pointer;

				&.icon--show-password {
						mask: url("../../img/icon/eye.svg") no-repeat center;
						opacity: 1;
						mask-size: 100%;
						background: $main-icon;
						@media screen and (max-device-width: 1199px) {
								display:none;
						}
				}
		}

		&--arrow {
				width: 18px;
				margin-right: 0;
				mask: url("../../img/icon/arrows/arrow_bottom.svg") no-repeat center;
				opacity: 1;
				mask-size: 100%;
				transition: .3s transform;
				background: $main-icon;

				&--up {
						transform: rotate(180deg);
				}

				&--right {
						transform: rotate(-90deg);
				}
		}

		&--info {
			width: 12px;
			height: 12px;
			margin: 0 0 0 3px;
			mask: url("../../img/icon/info-icon.svg") no-repeat center;
			background: $main-icon;
		}

		&--edit {
			mask: url("../../img/icon/edit.svg") no-repeat center;
			background: $main-icon;
			mask-size: 80%;
		}

		&--filter {
			width: 18px;
			margin-right: 0;
			mask: url("../../img/icon/filters.svg") no-repeat center;
			mask-size: contain;
			background: $main-icon;
			transition: .3s transform;
		}

		&--access {
			mask: url("../../img/icon/access.svg") no-repeat center;
			background: $main-icon;
		}

		&--shopping {
			mask: url("../../img/icon/shopping_icon.svg") no-repeat center;
			background: $main-icon;
		}

		&--tickets {
			width: 21px;
			height: 16px;
			margin-right: 0;
			mask: url("../../img/icon/tickets-number-icon.svg") no-repeat center;
			mask-size: contain;
			background: $main-icon;
			transition: .3s transform;
		}

		&--sector {
			width: 21px;
			height: 16px;
			margin-right: 0;
			mask: url("../../img/icon/sector-icon.svg") no-repeat center;
			mask-size: contain;
			background: $main-icon;
			transition: .3s transform;
		}

		&--search {
			width: 16px;
			height: 16px;
			margin-right: 0;
			mask: url("../../img/icon/search-icon.svg") no-repeat center;
			mask-size: contain;
			background: $main-icon;
			transition: .3s transform;
		}

		&--small-arrow {
			width: 18px;
			margin-right: 0;
			mask: url("../../img/icon/arrows/arrow_right.svg") no-repeat center;
			mask-size: contain;
			transform: rotate(90deg);
			background: $main-icon;
			transition: .3s transform;

			&--up {
					transform: rotate(270deg);
			}

			&--right {
					transform: rotate(0deg);
			}

			&--left {
					transform: rotate(-180deg);
			}
		}

		&--ticket {
			mask: url("../../img/icon/ticket.svg") no-repeat center;
			width: 15px;
			background: $main-icon;
			mask-size: 100%;
		}

		&--edit-btn {
			mask: url("../../img/icon/edit_btn.svg") no-repeat center;
			mask-size: 100%;
			background: $main-icon;

			&--green {
				background: $button;
			}

			&--white {
				background: $white;
			}

			&--small {
				width: 20px;
				height: 20px;
			}
		}

		&--member-card {
			mask: url("../../img/icon/member-card.svg") no-repeat center;
			mask-size: 100%;
			background: $main-icon;

			&--green {
				background: $button;
			}
		}

		&--document-card {
			mask: url("../../img/icon/document-card.svg") no-repeat center;
			mask-size: 100%;
			background: $main-icon;

			&--green {
				background: $button;
			}
		}

		&--forward-pagination {
			mask: url("../../img/icon/pagination/pagination_forward.svg") no-repeat center;
			mask-size: 100%;
			background: $disabled-icon;
			height: 18px;
			width: 10px;
			&--active {
				background: $button;
			}
		}

		&--forward-long-pagination {
			mask: url("../../img/icon/pagination/pagination_forward_long.svg") no-repeat center;
			mask-size: 100%;
			background: $disabled-icon;
			height: 18px;
			width: 14px;
			&--active {
				background: $button;
			}
		}

		&--back-pagination {
			mask: url("../../img/icon/pagination/arrow_back.svg") no-repeat center;
			mask-size: 100%;
			background: $disabled-icon;
			height: 18px;
			width: 10px;
			&--active {
				background: $button;
			}
		}

		&--back-long-pagination {
			mask: url("../../img/icon/pagination/arrow_back_long.svg") no-repeat center;
			mask-size: 100%;
			background: $disabled-icon;
			height: 18px;
			width: 14px;
			&--active {
				background: $button;
			}
		}

		&--warning {
			mask: url("../../img/icon/warning-icon.svg") no-repeat center;
			mask-size: 100%;
			background: $delete-icon;
		}

		&--hourglass {
			mask: url("../../img/icon/hourglass.svg") no-repeat center;
			mask-size: 100%;
			background: $main-icon;
		}

		&--available-balance {
			mask: url("../../img/icon/icon-available-balance.svg") no-repeat center;
			mask-size: 100%;
			background: $main-icon;
		}

		&--payout-tickets {
			mask: url("../../img/icon/payout-tickets.svg") no-repeat center;
			background-size: contain;
			background: $main-icon;
		}

		&--magnifying-glass {
			mask: url("../../img/icon/magnifying-glass.svg") no-repeat center;
			mask-size: 100%;
			background: $button;
		}

		&--eye {
			mask: url("../../img/icon/eye.svg") no-repeat center;
			mask-size: 100%;
			width: 17px;
			height: $height-icon-eye;
			margin-right: 0;
			background: $main-icon;
			&--closed {
				mask: url("../../img/icon/closed-eye.svg") no-repeat center;
			}
		}

		&--trash-can {
			mask: url("../../img/icon/trash-can.svg") no-repeat center;
			mask-size: 100%;
			background: $delete-icon;
			&--small {
				width: 12px;
				height: 14px;
				margin-right: 8px;
			}

			&--xs {
				width: 20px;
				height: 22px;
			}
		}

		&--logout {
			mask: url("../../img/icon/logout.svg") no-repeat center;
			mask-size: 100%;
			background: $white;
		}

		&--close {
			mask: url("../../img/icon/close-modal.svg") no-repeat center;
			mask-size: 100%;
			background: $main-icon;

			&--small {
				width: 10px;
				height: 10px;
			}
		}

		&--close-search {
			background: url("../../img/icon/close_search.svg") no-repeat center;
			background-size: contain;
			border-radius: 100% !important;
			width: 13px;
			height: 13px;
		}

		&--clear {
			mask: url("../../img/icon/clear.svg") no-repeat center;
			mask-size: 100%;
			background: $main-icon;
			&--small {
					width: 12px;
					height: 12px;
			}

			&--xsmall {
					width: 6.4px;
					height: 6.4px;
			}
		}

		&--white {
			background-color: $white;
		}

		&--no-margin {
				margin: 0!important;
		}

		&--red {
			background-color: $button-delete !important;
			opacity: 1 !important;
		}

		&--black {
			background: $black;
		}

		&--default-no-opacity {
			background: $main-icon;
		}

		&--default-heading {
				background: $main-text;
		}

		&--default {
				background: $main-icon;
				opacity: .5;
		}
}
