@import "../abstracts/variables";

.MuiFormControl-root{
  font-family: "Montserrat", sans-serif!important;
}
.MuiFormHelperText-root{
  font-family: "Montserrat", sans-serif!important;
}
.MuiFormHelperText-root.Mui-error{
  color: #dc3545!important;
  font-weight: 600;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
  .MuiIconButton-root{
    padding: 0;
  }
}
