.checkout-modal {
    @include media-breakpoint-up(md) {
        width: 570px;
    }

    @include media-breakpoint-up(lg) {

        width: 540px;
    }

    &__content {
        @include media-breakpoint-up(md) {
            height: 465px;
        }

        &__error {
            margin-bottom: 0;
            margin-top: 8px;
            font-weight: 600;
            font-size: 10px;
            color: #F23254;
        }

        .modal-header {
            @include media-breakpoint-down(sm) {
                position: fixed;
                width: 100vw;
                z-index: 1;
            }

            @include media-breakpoint-up(md) {
                display: block;
                padding-top: 0 !important;
                margin-top: 29px;
                padding-bottom: 25px !important;
            }
        }

        .modal-body {
            display: flex;
            flex-direction: column;
            overflow: auto;

            @include media-breakpoint-down(sm) {
                min-height: calc(100vh - 62px);
            }

            .container {
                @include media-breakpoint-down(sm) {
                    height: calc(100vh - 142px);
                    overflow: auto;
                    margin-top: 65px;
                }
            }
        }

        .modal-footer {
            padding-bottom: 0;
            border: none;
            @include media-breakpoint-down(sm) {
                justify-content: space-between;
            }
            justify-content: center;
        }

        &__tickets {
            @include media-breakpoint-up(md) {
                //overflow: auto;
                //margin-bottom: 45px;
                //height: 228px;
            }

            @include media-breakpoint-up(lg) {
                margin-bottom: 20px;
            }

            & div:last-child .checkout-modal__content__ticket {
                border: none;
            }
        }

        &__header{
            margin-top: 42px;
            margin-bottom: 21px;

            @include media-breakpoint-up(md) {
                margin-top: 0;
            }

            &__title {
                line-height: 20px;

                @include media-breakpoint-up(md) {
                    margin: 0;
                    padding-left: 20px;
                    line-height: 24px;
                }
            }
        }

        &__ticket {
            //margin-left: 15px;
            //margin-right: 15px;
            padding-top: 8px;
            padding-bottom: 9px;
            border-bottom: 1px solid #EDEDED;

            @include media-breakpoint-up(md) {
                margin-left: 20px;
                margin-right: 20px;
            }
        }

        &__match {
            @include media-breakpoint-up(md) {
                display: flex;
                flex-direction: row-reverse;
                justify-content: flex-end;
                align-items: baseline;
            }

            &__type {
                color: $first-button;
                line-height: 16px;
                margin: 0 0 2px;
                text-transform: capitalize;

                @include media-breakpoint-up(md) {
                    margin-left: 8px;
                }
            }

            &__name {
                font-weight: 600;
                line-height: normal;
                letter-spacing: 0.01em;
                margin-bottom: 4px;
                width: 100%;

                span {
                    font-weight: 500;
                    letter-spacing: 0.01em;
                    color: $body-color;
                }
            }
        }

        &__stadium {
            line-height: normal;
            text-transform: capitalize;

            @include media-breakpoint-up(md) {
                line-height: 19px;
            }
        }

        &__buttons {
            display: flex;
            justify-content: space-between;

            @include media-breakpoint-down(sm) {
                flex-direction: column-reverse;
                button {
                    width: 100%!important;
                    margin: 10px 0;
                }
            }

            @include media-breakpoint-only(sm) {
                margin: auto;
                max-width: 400px;
                width: 400px;
                padding-top: 20px;
                padding-bottom: 40px;
            }

            @include media-breakpoint-up(md) {
                margin: auto;
                max-width: 360px;
                width: 360px;
                padding-top: 20px;
                padding-bottom: 40px;
            }

            @include media-breakpoint-up(lg) {
                margin: auto;
                max-width: 350px;
                width: 350px;
            }

            a,
            button {
                position: relative;
                height: 31px;
                width: 165px;
                line-height: 1;
                font-size: 12px;

                @include media-breakpoint-up(md) {
                    width: 150px;
                }

                .btn__text-gradient {
                    position: absolute;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
                .btn__text {
                    position: absolute;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    &__fixed-bar {
        position: fixed;
        background: #FFFF;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
        padding-top: 15px;
        padding-bottom: 15px;

        @include media-breakpoint-down(sm) {
            display: none;
            max-width: 395px;
        }
    }
}

.upload-modal {
    &__progress {
        display: flex;
        justify-content: space-between;
        position: relative;
        padding: 0 20px;
        margin-top: 27px;

        @include media-breakpoint-between(md, lg) {
            margin-left: 35px;
            margin-right: 35px;
        }

        @include media-breakpoint-up(lg) {
            margin-left: 45px;
            margin-right: 45px;
        }
        :last-child {
            //display: none!important;
            .sep {
                display: none!important;
            }
        }


        &__step {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            font-size: 14px;
            height: 25px;
            width: 25px;
            background: #FFFFFF;
            box-shadow: 0 4px 40px rgba(14, 21, 85, 0.1);
            border-radius: 100px;

            color: $body-color;
            font-weight: 500;
            cursor: pointer;

            &.disabled{
                background: rgba(0,0,0,0.1);
                cursor: not-allowed!important;
            }

            .sep{
                position: absolute;
                height: 1px;
                width: 100px;//calc(100% - 130px);

                background: $headings-color;
                opacity: 0.1;
                border-radius: 2px;
                top: 50%;
                left: 0;
                transform: translate(45px, -50%);
            }

            &:hover{
                &:not(.disabled){
                    background: $headings-color !important;
                    color: $white !important;
                }
            }

            &__label {
                position: absolute;
                color: $main-text;
                bottom: -15px;
                margin-bottom: 0;
                font-weight: 500;
                line-height: normal;
                font-size: 10px;
                text-align: center;
                left: 50%;
                transform: translate(-50%, 0);
            }

            &.active {
                background: $headings-color;
                color: white;
                font-weight: bold;
            }
        }
    }
}
