.tickets {

    &__container {
        margin-top: 35px;

        @include media-breakpoint-up(lg) {
            margin-top: 0;
        }

        &__info {

        }
    }

    &.container {
        margin-bottom: 52px;
    }

    &__header {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 16px;

        &--bundles {
            @include media-breakpoint-down(md) {
                margin-bottom: 20px;
            }
        }

        @include media-breakpoint-between(md, lg) {
            font-size: 18px;
        }

        @include media-breakpoint-up(lg){
            //padding-bottom: 61px;
            padding-bottom: 12px;
            font-size: 24px;
        }

        .icon {
            height: 6px;
            width: 10px;
            margin-left: 10px;

            @include media-breakpoint-up(md) {
                height: 10px;
                width: 18px;
                margin-left: 20px
            }
        }

        &.collapsed {
            .icon--arrow {
                transform: rotate(0);
            }
        }
    }

    &__tableHeader {

        &::after {
            content: ' ';
            position: absolute;
            left: 50%;
            bottom: 0;
            height: 1px;
            width: calc(100% - 30px);
            background-color: #F2F2F2;
            transform: translate(-50%,0);
        }

        &__contents {
            margin-left: 30px;
            margin-bottom: 10px;
        }

        &__content {
            font-size: 10px;
            color: map_get($theme-colors, "heading-color");
            font-weight: 600;
            margin: 0;
            text-transform: uppercase;

            &:last-child {
                margin-left: 208px;
            }
        }
    }

    & .filter__head {
        margin-bottom: 0;
        justify-content: space-between;
    }

    &__show-more {
        justify-content: center;
        margin-top: 15px;

        .btn {
            padding: 0;
            width: 160px;
            height: 31px;
            background: $button;
        }
    }
}
