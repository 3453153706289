.hero {
    padding-top: 25px;
    background: url("../../img/homePage/hero-bg.png") no-repeat local right -10px;
    background-size: 32vmax;
    height: 520px;

    @include media-breakpoint-only (sm){
        height: 570px;
        background-size: 35vmax;
        background-position: right -41px;
        margin-top: 10px;
        padding-top: 56px;
    }

    @include media-breakpoint-between (md, lg){
        height: 570px;
        background-size: 48vmax;
        background-position: right -65px;
        margin-top: 45px;
        padding-top: 25px;
    }

    @include media-breakpoint-between(lg, xl){
        height: 72vw;
        max-height: 825px;
        padding-top: 60px;
        background-size: 65vmax;
        background-position: right -100px;
    }

    @include media-breakpoint-up (xl){
        height: 80vw;
        max-height: 1000px;
        padding-top: 70px;
        background-size: inherit;
        background-position: right -135px;
    }

    .container .announcements{
        padding-top: 15px;
        width: 78%;

        @include media-breakpoint-up (md){
            width: 63%;
        }
    }
}

.hero_not_found {
    margin-top: 16px;
    background-size: auto calc(80vw);
    background: url("../../img/hero_not_found.png") no-repeat local right -30px;
    height: 470px;
    min-height: 70vh;

    &::after {
        @include media-breakpoint-up(md) {
            content: ' ';
            position: absolute;
            background: url("data:image/svg+xml;utf8,%3Csvg%20width%3D%22288%22%20height%3D%22387%22%20viewBox%3D%220%200%20288%20387%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20opacity%3D%220.23%22%20d%3D%22M62.5661%20341.469C-6.08453%20301.834%20-19.6417%20196.792%2032.2854%20106.851C84.2125%2016.9111%20181.96%20-23.8691%20250.611%2015.7664C319.261%2055.4019%20279.728%20242.078%20227.801%20332.018C175.874%20421.958%20131.217%20381.105%2062.5661%20341.469Z%22%20fill%3D%22url(%23paint0_linear)%22%2F%3E%0A%3Cdefs%3E%0A%3ClinearGradient%20id%3D%22paint0_linear%22%20x1%3D%22186.869%22%20y1%3D%22413.236%22%20x2%3D%22329.013%22%20y2%3D%2216.6547%22%20gradientUnits%3D%22userSpaceOnUse%22%3E%0A%3Cstop%20offset%3D%220.0246272%22%20stop-color%3D%22#{$second-button}%22%2F%3E%0A%3Cstop%20offset%3D%221%22%20stop-color%3D%22#{$second-button}%22%2F%3E%0A%3C%2FlinearGradient%3E%0A%3C%2Fdefs%3E%0A%3C%2Fsvg%3E%0A") no-repeat;
            z-index: -1;
            left: -273px;
            top: 230px;
            width: 290px;
            height: 390px;
            transform: rotate(27deg)
        }

        @include media-breakpoint-up(xl) {
            left: -205px;
            top: 230px;
            transform: rotate(27deg)
        }
    }

    @include media-breakpoint-down(md) {
        height: 238px;
        background-size: 58vmax;
        background-position: 25vw -85px;
        margin-top: 54px;
        padding-top: 25px;
    }

    @include media-breakpoint-between (md, lg){
        height: 570px;
        background-size: 58vmax;
        background-position: right -82px;
        margin-top: 54px;
        padding-top: 25px;
    }

    @include media-breakpoint-between(lg, xl){
        height: 72vw;
        max-height: 825px;
        padding-top: 60px;
        background-size: 65vmax;
        background-position: right -100px;
    }

    @include media-breakpoint-up (xl){
        height: 80vw;
        max-height: 1000px;
        padding-top: 70px;
        background-size: inherit;
        background-position: right -135px;
    }

}
