.sell-page {
		@include media-breakpoint-up(lg) {
				background: url("data:image/svg+xml;utf8,%3Csvg%20width%3D%22288%22%20height%3D%22387%22%20viewBox%3D%220%200%20288%20387%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20opacity%3D%220.23%22%20d%3D%22M62.5661%20341.469C-6.08453%20301.834%20-19.6417%20196.792%2032.2854%20106.851C84.2125%2016.9111%20181.96%20-23.8691%20250.611%2015.7664C319.261%2055.4019%20279.728%20242.078%20227.801%20332.018C175.874%20421.958%20131.217%20381.105%2062.5661%20341.469Z%22%20fill%3D%22url(%23paint0_linear)%22%2F%3E%0A%3Cdefs%3E%0A%3ClinearGradient%20id%3D%22paint0_linear%22%20x1%3D%22186.869%22%20y1%3D%22413.236%22%20x2%3D%22329.013%22%20y2%3D%2216.6547%22%20gradientUnits%3D%22userSpaceOnUse%22%3E%0A%3Cstop%20offset%3D%220.0246272%22%20stop-color%3D%22#{$second-button}%22%2F%3E%0A%3Cstop%20offset%3D%221%22%20stop-color%3D%22#{$first-button}%22%2F%3E%0A%3C%2FlinearGradient%3E%0A%3C%2Fdefs%3E%0A%3C%2Fsvg%3E%0A") calc(100% + 370px) 220px no-repeat;
				background-size: auto, auto;
		}

		margin-top: 30px;

		@include media-breakpoint-between(md,lg) {
				margin-top: 26px;
		}

		@include media-breakpoint-up(lg) {
				margin-top: 42px;
		}

		&__back-btn,
		&__btn {
			margin-top: 40px;
			position: relative;
		}

		//&__btn {
		//	@include media-breakpoint-up(lg) {
		//		max-width: 160px;
		//	}
		//}

		&__single-ticket-subtitle {
			margin-bottom: 12px;
			margin-top: 36px;
			font-size: 12px;

			@include media-breakpoint-up(md) {
				font-size: 14px;
				margin-top: 32px;
				margin-bottom: 16px;
			}

			@include media-breakpoint-up(lg) {
				margin-top: 56px;
			}
		}

		&__title {
				font-weight: 600;
				margin-bottom: 40px;

				@include media-breakpoint-between(md, lg) {
						margin-bottom: 13px;
				}

				@include media-breakpoint-up(lg) {
						margin-bottom: 34px;
				}
		}

		.ticket-cards {

				&__header {
						&__title {
								padding-bottom: 50px;

								@include media-breakpoint-between(md,lg) {
										padding-bottom: 60px;
								}

								@include media-breakpoint-up(lg) {
										padding-top: 8px;
										margin-bottom: 56px;
										padding-bottom: 0;
								}

								&__label {
										font-size: 14px;
										line-height: 24px;
										margin-bottom: 16px;
								}
						}

						&__button {
								max-width: 248px;
								margin-top: 0;
						}
				}
		}

		.sell {
				&__tickets {
						margin-top: 0;

						&__header {
								//margin-left: 0;
								margin-top: 24px;
								justify-content: space-between;

								&__label {
										&--match {
												margin-left: 20px;

												@include media-breakpoint-up(lg) {
														margin-left: 24px;
												}
										}

										&--details {
												display: none;
										}

										&--refund {
												margin-left: 0;
												margin-right: 170px;

												@include media-breakpoint-up(lg) {
														margin-right: 161px;
												}

												@include media-breakpoint-up(xl) {
														margin-right: 254px;
												}
										}
								}
						}
				}

				&-modal {
						&__modal {
								&__content {
										&__info {
												&__price {
														font-size: 16px;
														margin-bottom: 36px;

														@include media-breakpoint-between(md,lg) {
																font-size: 18px;
														}

														@include media-breakpoint-up(lg) {
																font-size: 24px;
														}
												}
										}
								}
						}
				}

		}

		.summary {
				margin-top: 70px;
				margin-bottom: 80px;

				@include media-breakpoint-up(lg) {
						margin-top: 74px;
						margin-bottom: 80px;
				}
				.sell-ticket__contents {
						box-shadow: none !important;
						padding: 0;


						&--left-side {
								@include media-breakpoint-up(md) {
										width: 100%;
								}
						}

						&__header__title {
								@include media-breakpoint-up(xl) {
										margin-bottom: 5px;
								}
						}

						&__info {
								margin-top: 9px;

								@include media-breakpoint-up(md) {
										justify-content: space-between;
								}

								@include media-breakpoint-up(xl) {
										margin-top: 2px;
								}

								&__ticket {
										@include media-breakpoint-up(md) {
												margin-left: 0;
										}

										@include media-breakpoint-up(xl) {
												width: 300px;
										}
								}

								&__match {
										@include media-breakpoint-up(xl) {
												width: 220px;
										}
								}
						}


						&__sell {
								margin-top: 27px;

								@include media-breakpoint-up(md) {
										flex-direction: row;
										margin-top: 0;
										height: 21px;
								}

								&__price {
										margin-right: 0;
								}
						}

				}
		}

		.checkpoint__element {
				padding-top: 12px;
				@include media-breakpoint-up(md) {
						padding-top: 24px;
				}

				&--no-padding-top {
						padding-top: 0;
				}
		}

		&__modal {
				@include media-breakpoint-between(md,lg) {
						max-width: 330px;
				}

				@include media-breakpoint-up(lg){
						max-width: 350px;
				}

				&__content {
						@include media-breakpoint-up(md) {
								height: auto;
						}

						&__buttons {
								@include media-breakpoint-up(lg) {
										margin-top: 24px;
										margin-bottom: 39px;
								}
						}
				}

				&__title {
						display: none;
						padding-top: 39px;
						font-weight: $font-weight-semi-bold;
						margin-bottom: 0;

						@include media-breakpoint-up(md) {
								display: block;
						}
				}

				&__paragraph {
						&--top {
								padding-top: 59px;
								margin-bottom: 0;

								@include media-breakpoint-up(md) {
										padding-top: 24px;
								}
						}
						&--bottom {
								padding-top: 8px;
						}
				}
		}

		.faq {
			margin-top: 34px;
			margin-bottom: 80px;

			@include media-breakpoint-between(md, lg) {
				margin-top: 50px;
				margin-bottom: 100px;
			}

			@include media-breakpoint-up(lg) {
				margin-top: 70px;
				margin-bottom: 120px;
			}
		}
}
