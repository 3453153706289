.checkpoint {
		display: flex;
		align-items: center;

		&--inline {
				display: flex;
				justify-content: space-between;
				padding-top: 52px;
				padding-bottom: 116px;

				&--sell-page {
					@include media-breakpoint-between(md, lg) {
						padding-left: 66px;
						padding-right: 66px;
					}

					@include media-breakpoint-up(lg) {
						padding-left: 118px;
						padding-right: 118px;
					}
				}

				@include media-breakpoint-between(md,lg) {
						max-width: 630px;
						margin: auto;
				}

				@include media-breakpoint-up(lg) {
						padding-top: 31px;
						padding-bottom: 108px;
						max-width: 775px;
						margin: auto;
				}
		}

		&__state {
				display: inline-flex;
				flex-direction: column;
				align-items: center;
				position:relative;

				&:not(:last-child) {
						&:after {
								content: '';
								width: 104px;
								height: 2px;
								position: absolute;
								background: $title;
								opacity: 0.1;
								border-radius: 2px;
								top: 50%;
								transform: translateY(-50%);
								left: calc(100% + 18px);

								@include media-breakpoint-up(lg) {
									width: 139px;
								}
						}
				}

				&--sell-page {
					&:not(:last-child) {
						&:after {
								width: 158px !important;
						}
				}
				}
		}

		&__header {
				position: relative;

				@include media-breakpoint-up(md) {
						display: none;
				}

				&.active {
						display: block;
				}

				&.deactivated {
						margin-bottom: 8px;
						padding-bottom: 42px;

						&:not(:last-child) {
								&:after {
										content: "";
										position: absolute;
										width: 2px;
										height: calc(100% - 36px);
										left: 29px;
										top: 36px;
										background: $title;
										opacity: 0.1;
										border-radius: 2px;
								}
						}
				}
		}

		&__row {
				position:relative;
				padding-top: 8px;
				margin-top: 8px;
				padding-bottom: 42px;
				margin-bottom: 8px;

				@include media-breakpoint-up(md) {
						padding-top: 0;
						margin-top: 14px;
				}

				&:after {
						content: "";
						position: absolute;
						width: 2px;
						height: 100%;
						left: 29px;
						top: 0;
						background: $title;
						opacity: 0.1;
						border-radius: 2px;

						@include media-breakpoint-up(md) {
								width: calc(100% - 30px);
								height: 1px;
								left: 15px;
								top: 0;
						}
				}

				&--last{
						padding-bottom: 32px;
						padding-top: 24px;

						&:after {
								@include media-breakpoint-down(sm) {
										content: none;
								}
						}
				}

				//&:not([title]):after {
				//		content: none;
				//
				//		@include media-breakpoint-up(md) {
				//				position: absolute;
				//				content: "";
				//				width: calc(100% - 30px);
				//				height: 1px;
				//				left: 15px;
				//				top: 0;
				//				background: $title;
				//				opacity: 0.1;
				//				border-radius: 2px;
				//		}
				//
				//		@include media-breakpoint-up(lg) {
				//				width: calc(100% - 30px);
				//		}
				//}

				&--deactivated {
						padding-top: 17px;
						padding-bottom: 17px;

						@include media-breakpoint-up(md){
								display: none;
						}
				}

		}

		&__element {
				@include media-breakpoint-up(md) {
						padding-top: 43px;
				}

				&--all-good {
						margin-bottom: 23px;
				}
		}

		&__number {
				width: 28px;
				height: 28px;
				display: inline-block;
				margin-right: 3px;
				box-shadow: 0 4px 40px rgba(14, 21, 85, 0.1);
				border-radius: 30px;
				background: $white;

				font-weight: $font-weight-semi-bold;
				line-height: 28px;
				font-size: 14px;
				text-align: center;
				color: $body-color;

				@include media-breakpoint-up(md) {
						width: 37px;
						height: 37px;

						line-height: 37px;
						font-size: 16px;
				}

				@include media-breakpoint-up(lg) {
						width: 51px;
						height: 51px;

						line-height: 51px;
						font-size: 18px;
				}

				.active & {
						background: $title;
						color: set-button-text-color($title);
						font-weight: $font-weight-bold;
				}

				.checkpoint__header.active & {
						@include media-breakpoint-up(md) {
								width: initial;
								height: initial;
								background: none;
								box-shadow: none;
								border-radius: 0;
								font-size: inherit;
								font-weight: 500;
								color: $headings-color;
								line-height: inherit;

								&:after {
										content: ". ";
								}
						}
				}

				.active &__label {
						color: $title;
				}

				&__label {
						position: absolute;
						width: 200px;
						top: 46px;

						@include media-breakpoint-up(lg) {
								top: 60px;
						}
				}
		}

		&__title {
				color: $main-text;
				margin-bottom: 0;

				.active &{
						color: $title;
				}
		}
}
