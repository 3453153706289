.sell-modal {
    &__modal {
        @include media-breakpoint-down(sm) {
            margin: -1px;
            height: 100%;
            max-width: none;
        }

        @include media-breakpoint-up(md) {
            max-width: 450px;
            display: flex;
            align-items: center;
            min-height: calc(100% - (0.5rem * 8));
        }

        @include media-breakpoint-up(lg) {
            max-width: 540px;
        }

        &__content {
            height: calc(100vh + 1px);
            //height: calc(100% + 1px);
            //max-height: 667px;

            @include media-breakpoint-down(sm) {
                border-radius: 0;
                border: none;
            }

            @include media-breakpoint-up(md) {
                height: 480px;
            }

            @include media-breakpoint-up(lg) {
                height: 492px;
            }

            &--confirmation {
                @include media-breakpoint-up(md) {
                    height: 597px;
                }

                @include media-breakpoint-up(lg) {
                    height: 554px;
                }
            }

            .modal-header {
                padding: 22.25px;
                border: none;

                @include media-breakpoint-down(sm) {
                    background: #FFFFFF;
                    mix-blend-mode: normal;
                    box-shadow: 0 4px 40px rgba(14, 21, 85, 0.1);
                }

                .modal-title {
                    font-weight: 600;
                    line-height: normal;
                    font-size: 16px;
                    text-align: center;
                    color: map_get($theme-colors, "heading-color");

                    @include media-breakpoint-up(md) {
                        font-weight: 500;
                    }

                    @include media-breakpoint-up(lg) {
                        font-size: 18px;
                    }
                }

                .close {
                    padding: 0;
                    margin: 0;
                    width: 19.5px;
                    height: 19.5px;

                    .icon {
                        margin: 0;
                        width: 19.5px;
                        height: 19.5px;
                    }
                }
            }

            .modal-body {
                display: flex;
                flex-direction: column;

                @include media-breakpoint-up(md) {
                    padding-left: 40px;
                    padding-right: 40px;
                }
            }

            &__header {
                margin-top: 30px;
                margin-bottom: 6px;
                display: flex;
                align-items: baseline;
                flex-direction: column;

                @include media-breakpoint-up(md) {
                    margin-top: 8px;
                }

                &__title {
                    font-size: 18px;
                    font-weight: 600;
                    line-height: normal;
                    margin: 0 8px 0 0;
                    order: 2;

                    &__divider {
                        font-weight: 500;
                        color: map_get($theme-colors, "paragraph-color");
                    }
                }

                &__type {
                    letter-spacing: 0.01em;
                    color: $first-button;
                }
            }

            &__info {
                &__username {
                    margin-top: 16px;
                }

                &__price {
                    margin: 16px 0 46px;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 24px;
                    background: $button;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    white-space: nowrap;
                }
            }

            &__buttons {
                display: flex;
                justify-content: center;
                margin-top: 30px;

                @include media-breakpoint-up(md) {
                    margin-top: 14px;
                    margin-bottom: 15px;
                }

                .btn {
                    position: relative;
                    justify-content: center;
                    align-items: center;
                    height: 31px;
                    width: 165px;
                    padding: 0;

                    &:first-child {
                        margin-right: 15px;

                        @include media-breakpoint-up(md) {
                            margin-right: 30px;
                        }
                    }
                }
            }
        }

        &__confirmation {
            &__title {
                margin-top: 28px;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 0.01em;

                @include media-breakpoint-up(md) {
                    margin-top: 6px;
                }
            }

            &__subtitle {
                line-height: 20px;
                margin: 0;

                @include media-breakpoint-up(md) {
                    line-height: 24px;
                }
            }

            &__ticket-info {

                label {
                    line-height: 20px;
                    display: block;
                }

                &__title {
                    line-height: 29px;
                    margin-bottom: 5px;
                }

                &__stadium {
                    margin-bottom: 1px;
                }

                &__sector {
                    margin-bottom: 8px;
                }

                &__member-card {
                    margin-bottom: 10px;
                }

                &__username {
                    font-size: 14px !important;
                }
            }

            &__price {
                padding-top: 10px;
                padding-bottom: 25px;
                font-size: 24px;
                font-weight: 600;
                line-height: 24px;
                background: $button;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                white-space: nowrap;
            }

            &__conditions {
                font-family: 'Montserrat', serif;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                letter-spacing: 0.01em;
            }

            &__button {
                @include media-breakpoint-between(md, lg) {
                    width: 150px !important;
                }

                @include media-breakpoint-up(lg) {
                    width: 160px !important;
                }
            }
        }
    }
}
