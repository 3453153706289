.search-box {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 50px;

    @include media-breakpoint-between(md, lg) {
        margin-bottom: 60px;
    }

    &--control {
        border: none;
        padding-left: 0;
        text-transform: capitalize;
        font-size: 16px !important;
        line-height: normal !important;
        letter-spacing: 0.01em;
        border-bottom: 1px solid transparentize($color: $headings-color, $amount: 0.9);
        transition: border-color 0.3s ease-in-out;

        &:active,
        &:focus,
        &:hover {
            outline: none;
            border-color: $headings-color;
        }

        &:hover,
        &:focus,
        &:active {
            & + .search-box--append {
                display: none;
            }
        }
    }

    &--append {
        z-index: 999;
        position: absolute;
        right: 0;
    }

    &--btn {
        cursor: pointer;
        background: none;
    }
}
