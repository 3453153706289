.sell-ticket {
    margin-bottom: 10px;
    box-shadow: none;
    transition: box-shadow 0.3s ease-in-out;

    .sell-ticket__contents {
        transition: box-shadow 0.3s ease-in-out;
        box-shadow: none;
    }

    &__full_name{
        font-family: 'Montserrat', serif !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 16px !important;
        letter-spacing: 0.01em !important;
        color: #5E7891 !important;
    }

    &:hover {
        transition: all 0.3s ease-in-out;
        .sell-ticket__contents {
            box-shadow: 0 4px 40px rgba(14, 21, 85, 0.1);
        }

        .sell-ticket__contents__sell {
        }
    }

    &__contents {
        background: #FFFFFF;
        border-radius: 3px;
        padding: 16px;

        @include media-breakpoint-down(sm) {
            box-shadow: 0 4px 40px rgba(14, 21, 85, 0.1);
        }

        @include media-breakpoint-up(md) {
            flex-direction: column;
            display: flex;
            padding: 16px 10px;
        }

        @include media-breakpoint-up(lg) {
            padding: 16px 12px;
        }

        &__wrapper {
            justify-content: space-between;
            @include media-breakpoint-up(md) {
                align-items: flex-start;
            }

            &--acquired {
                @include media-breakpoint-up(md) {
                    align-items: center;
                }
            }
        }

        &__tournament {
            display: flex;
            justify-items: center;
            margin-bottom: 8px;

            &__logo {
                margin-right: 4px;
                width: 20px;
                height: 20px;
            }
        }

        &__header {
            display: flex;
            flex-direction: column;
            align-items: baseline;

            &__title {
                font-size: 18px;
                font-weight: 500;
                line-height: 22px;
                margin-bottom: 4px;
                display: flex;
                flex-wrap: wrap;
                white-space: pre-wrap;
                align-items: baseline;

                @include media-breakpoint-between(md, lg) {
                    max-width: 290px;
                }

                @include media-breakpoint-up(lg) {
                    margin-top: 0;
                    line-height: 29px;
                    font-weight: 600
                }

                &__divider {
                    color:  $body-color;

                    @include media-breakpoint-up(lg){
                        font-weight: 500
                    }
                }
            }

            &__type {
                letter-spacing: 0.01em;
                color: $first-button;
            }
        }

        &__user-info {
            //@include media-breakpoint-between(md, lg) {
            //    flex: 0.34;
            //}
            //
            //@include media-breakpoint-up(lg) {
            //    flex: 0.38;
            //}

            &--acquired {
                display: flex;
                align-items: center;
                justify-content: space-between;

                //@include media-breakpoint-between(md, lg) {
                //    flex: 0.58;
                //}
                //
                //@include media-breakpoint-up(lg) {
                //    flex: 0.48;
                //}

                &__btn {
                    position: relative;
                    width: 100%;
                    max-width: 160px;
                    height: 31px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0;

                    @include media-breakpoint-up(lg) {
                        margin-top: 15px;
                        min-width: 160px;
                    }
                }
            }
        }

        &__info {
            //@include media-breakpoint-up(md) {
            //    display: flex;
            //    flex-direction: row;
            //}
            //
            //@include media-breakpoint-up(md) {
            //    flex: 0.56;
            //}
            //
            //&--acquired {
            //    @include media-breakpoint-up(lg) {
            //        flex: 0.52;
            //    }
            //}

            & label {
                line-height: 21px;
            }

            &__match {
                display: flex;
                flex-direction: column;
            }

            &__ticket {
                display: flex;
                flex-direction: column;
                margin-top: 24px;

                @include media-breakpoint-up(md) {
                    margin-top: 0;
                }

                &__username {
                    font-weight: 700;
                    @include media-breakpoint-up(md) {
                        font-size: 14px;
                        line-height: 24px;
                        font-weight: 600;
                    }

                    @include media-breakpoint-up(lg) {
                        font-size: 16px;
                        line-height: 27px;
                    }
                }
            }
        }

        &__sell {
            margin-top: 24px;
            display: flex;
            align-items: center;

            @include media-breakpoint-up(md) {
                margin-top: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            @include media-breakpoint-up(lg) {
                flex-direction: row;
                justify-content: space-between;
            }

            &__price-wrapper {
                @include media-breakpoint-down(md) {
                    width: 100%;
                }

                @include media-breakpoint-between(md, lg) {
                    display: flex;
                    align-items: center;
                    white-space: pre;
                    justify-content: space-between;
                    width: 100%;
                }

                @include media-breakpoint-up(lg) {
                    width: unset;
                }
            }

            &__refund-label {
                text-transform: capitalize;
                line-height: 24px;
                font-weight: 600;
            }

            &__price {
                margin: 0;
                font-size: 14px;
                font-weight: 600;
                line-height: 14px;
                background: $button;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                white-space: nowrap;

                &__label {
                    font-size: 10px;
                    font-weight: 500;
                }
            }

            &__not-saleable {
                z-index: 1;
                width: 100%;
                font-weight: 600;
                line-height: 20px;
                text-align: center;
                letter-spacing: 0.05em;
                text-transform: uppercase;

                color: #EB5757;

                @include media-breakpoint-between(md, lg) {
                    margin-top: 12px;
                }

                @include media-breakpoint-up(lg) {
                    margin-top: 0;
                }
            }

            .btn {
                position: relative;
                margin-left: 5px;
                width: 100%;
                max-width: 160px;
                height: 31px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0;

                @include media-breakpoint-up(md) {
                    margin-top: 4px;
                }

                @include media-breakpoint-up(lg) {
                    margin-top: 0;
                    min-width: 160px;
                    max-width: 200px;
                }
            }
        }
    }

}
