// It it the search container, its the parent of the component where all
// is included and all is wrapped
.ticket-search {
    margin-top: 55px;

    @include media-breakpoint-between(md,lg) {
        margin-top: 38px;
    }

    @include media-breakpoint-up(lg) {
        margin-top: 68px;
    }

    &-separator {
        border-right: 1px solid rgba(29, 15, 81, 0.1);
    }

    &__title {
        text-transform: capitalize;
        margin-bottom: 20px;
        line-height: 20px;
        letter-spacing: 0.01em;
    }

    &__row {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;

        @include media-breakpoint-between(sm, md) {
            width: fit-content;
        }

        @include media-breakpoint-between(md, lg) {
            padding-top: 8px;
            padding-bottom: 8px;
        }

        @include media-breakpoint-up(lg){
            padding-top: 23px;
            padding-bottom: 22px;
        }
    }

    // "Thats the search form, it contain all the input fields"
    &__form {
        box-shadow: 0px -20px 30px rgba(29, 15, 81, 0.04), 0px -17px 30px rgba(29, 15, 81, 0.04);
        background: #FFFFFF;
        border-radius: 12px;

        @include media-breakpoint-down(sm) {
            padding: 0;
            border-radius: 12px 12px 0 0;
            box-shadow: 0px -50px 30px rgba(29, 15, 81, 0.04);
            width: 90vw;
        }
        @include media-breakpoint-between(sm, md) {
            width: 80vw;
        }

        @include media-breakpoint-up(md) {
            box-shadow: 0 10px 20px rgba(29, 15, 81, 0.1), 0 11px 70px rgba(29, 15, 81, 0.1);
        }
    }

    //It create the vertical line that divide each item
    &__col-md {

        @include media-breakpoint-between(md, lg) {
            @include make-vertical-sep(before, transparentize(map_get($theme-colors, "heading-color"), .9), 90%){
                left: 0;
            };
        }

        @include media-breakpoint-up(lg) {
            @include make-vertical-sep(before, transparentize(map_get($theme-colors, "heading-color"), .9), 90%){
                left: 0.7vw;
            }
        }
    }

    &__col-md-tickets {
        @include media-breakpoint-up(lg) {
            @include make-vertical-sep(before, transparentize(map_get($theme-colors, "heading-color"), .9), 90%);
        }
    }

    // That is the search label, it display a label before each input fields
    &__label {
        color: map_get($theme-colors, "heading-color");
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        font-size: 14px;
        margin-bottom: 0;
        margin-left: 12px;

        &__number {
            margin-left: 12px;
            font-weight: 600;
            font-size: 16px;
            line-height: normal;
            color: $main-text;
        }

        &--n-ticket {
            @include media-breakpoint-down(sm) {
                margin-bottom: 5px;
            }
        }

        @include media-breakpoint-up(md) {
            margin-left: 0;
            font-size: 12px;
            padding-top: 8px;
        }
    }

    // That is the text input field, where the user insert data (Match, team, ticket number, ecc.
    &__text-input {
        @extend .form-control;
        font-size: $label-font-size;
        color: map-get($theme-colors, "paragraph-color");
        border: none;
        padding: 0;
        height: auto;
        font-weight: 500;
        line-height: normal;

        @include media-breakpoint-down(sm) {
            border-radius: 4px;
            padding-left: 12px;
            padding-top: 8px;
            padding-bottom: 9px;
        }

        @include media-breakpoint-up(md) {
            font-size: $label-font-size + 2;

            margin-bottom: 9px;
        }

        &:focus {
            outline: none;
            box-shadow:none !important;
        }
    }

    // That is the search button, it trigger the form action, to archive a search request
    &__button {
        margin-top: 30px;
        max-width: 255px;
        width: 100%;
        @include media-breakpoint-down(sm) {
            box-shadow: none;
        }

        @include media-breakpoint-up(md) {
            margin-top: 0;
            //box-shadow: 0px 9px 28px rgba(108, 197, 111, 0.3);
        }

        @include media-breakpoint-between(md,lg){
            position: inherit;
            left: 0;
            transform: inherit;
            max-width: 181px;
            margin-top: 7px;
        }

        @include media-breakpoint-up(lg){
            position: inherit;
            left: 0;
            transform: inherit;
            max-width: 181px;
            margin-top: 2px;
        }
    }
}

.sell-your-ticket-separator {
    width: 82px;
    height: 2px;
    left: 166px;
    top: 2804px;

    background: $lines-and-box;
}
