.filters {
		&__header {
				display: flex;
				justify-content: space-between;
				align-items: center;

				&__element {
						display: flex;
						align-items: center;
						justify-content: center;
						padding-top: 0.7rem;
						padding-bottom: 0.7rem;
						background: #F5F6F7;
						border-radius: 2px;
						&__label {
								font-weight: $font-weight-semi-bold;
								font-size: 14px;
								color: $headings-color;
								user-select: none;

								&--sector {
										text-transform: capitalize;
								}

								&--filters {

										@include media-breakpoint-up(md) {
												text-transform: uppercase;
										}
								}

								&__icon {
										display: flex;

										&--arrow {
												width: 5px;
												height: 9px;
												margin-left: 7px;
										}

										&--filter {
												height: 15px;
												margin-left: 5px;

												@include media-breakpoint-between(md,lg) {
														margin-left: 10px;
												}
										}

										&--expanded {
												height: 15px;
												margin-right: 10px;
										}

								}

						}
				}

		}

		&__show {
				display: flex;
				align-items: center;
				padding: 0;
		}

		&__element {
				position: absolute;
				width: calc(100% - 30px);
				z-index: 900;
				max-height: 0;
				overflow: hidden;
				background: $white;
				box-shadow: 0 4px 40px rgba(14, 21, 85, 0.1);
				border-radius: 0 0 3px 3px;
				transition: all .3s;

				@include media-breakpoint-up(lg) {
						position: relative;
						max-height: 100%;
						overflow: initial;
						background: transparent;
						box-shadow: none;
						border-radius: 0;
				}

				&.active {
						max-height: 800px;
				}

				&__body {
						align-items: start;

						@include media-breakpoint-between(md,lg){
								padding: 0 15px;
						}

				}

				&--sector {

						@include media-breakpoint-up(lg) {
								margin-bottom: 20px;
						}

				}

		}

		&__modal {
				overflow: hidden;

				&-header {
						position: relative;
						align-items: center;
						box-shadow: none !important;

						&::after {
								content: '';
								position: absolute;
								left: 16px;
								top: 100%;
								height: 1px;
								width: calc(100% - 32px);
								background-color: rgba(28,15,79, 0.14);
						}
				}
				&__close {
						&-button {
								width: 11px !important;
								height: 11px !important;
								position: absolute;
						}
						&-icon {
								width: 11px !important;
								height: 11px !important;
								position: absolute;
								top: 0;
								left: 0;
						}
				}
				&__reset {
						&-button {
								cursor: pointer;
								text-transform: none;
								user-select: none;
								font-size: 14px;
								position: absolute;
								padding-right: inherit;
								right: 0;
						}
				}

				&__body {
						overflow: scroll;
						padding: 1rem calc(1rem + 15px) !important;
				}

				&__show-data {
						&-button {
								text-transform: uppercase;
								max-width: 215px;
						}
				}

				&__footer {
						border: none !important;
						position: relative;

						&::before {
								content: '';
								position: absolute;
								left: 16px;
								bottom: 100%;
								height: 1px;
								width: calc(100% - 32px);
								background-color: rgba(28,15,79, 0.14);
						}
				}
		}

}
