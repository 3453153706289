.pdp-store {
    margin-top: 30px;

    @include media-breakpoint-between(md, lg) {
        margin-top: 10px;
    }

    @include media-breakpoint-up(lg) {
        margin-top: 20px;
    }

    &__history {
        margin-bottom: 60px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 100px;
        }

        &,
        &__link {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;

            color: $main-text;
        }

        &__link {
            font-weight: 600;
        }

        &__link:hover,
        &__link:focus {
            outline: none;
            text-decoration: none;
            color: $main-text;
        }
    }

    &__product {
        margin-bottom: 100px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 120px;
        }

        &__name {
            margin-top: 20px;
            margin-bottom: 0;

            @include media-breakpoint-up(md) {
                margin-top: 0;
            }
        }

        &__description {
            margin-top: 8px;
            margin-bottom: 0;

            @include media-breakpoint-up(lg) {
                margin-top: 12px;
            }
        }

        &__price {
            margin-bottom: 0;
            margin-top: 20px;
            font-size: 10px;
            line-height: normal;

            @include media-breakpoint-between(md, lg) {
                margin-top: 10px;
            }

            @include media-breakpoint-up(lg) {
                margin-top: 24px;
            }

            span {
                font-size: 18px;
                line-height: inherit;

                @include media-breakpoint-up(lg) {
                    font-size: 24px;
                }
            }
        }

        &__add-to-cart-btn {
            box-shadow: none;
            width: 254px;
            height: 31px;
            font-weight: 600;
            font-size: 12px;
            letter-spacing: 0.05em;
            line-height: 1;
            margin-right: auto;
            display: flex;
            justify-content: center;
            margin-top: 26px;

            @include media-breakpoint-between(md, lg) {
                width: 200px;
                margin-top: 23px;
            }

            @include media-breakpoint-up(lg) {
                width: 240px;
                height: 51px;
                font-size: 13px;
                margin-top: 28px;
            }
        }
    }
}
