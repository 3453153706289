.contact-us {
    margin-top: 58px;

    @include media-breakpoint-down(md){
        margin-bottom: 100px;
    }

    @include media-breakpoint-between(md, lg){
        margin-top: 46px;
        margin-bottom: 84px;
    }

    @include media-breakpoint-up(lg){
        margin-top: 97px;
        margin-bottom: 195px;
    }

    &__header {
        @include media-breakpoint-up(lg){
            margin-top: 63px;
        }
    }

    &__title {
        font-weight: 400;
        //line-height: 64px;
        margin-bottom: 20px;

        @include media-breakpoint-between(md, lg){
            margin-bottom: 12px;
            font-size: 30px;
        }

        @include media-breakpoint-up(lg){
            font-size: 36px;
        }
    }

    &__paragraph,
    &__label {
        font-size: 14px !important;
    }

    &__label {
        margin-bottom: 5px;
        color: #5E7891;
        text-transform: uppercase;
    }

    &__paragraph {
        line-height: 26px;
        margin-bottom: 0;
        @include media-breakpoint-up(lg){
            font-size: 15px !important;
            margin-top: 22px;
            padding-right: 55px;
        }
    }

    &__ticketsender {
        margin-top: 50px;

        @include media-breakpoint-between(md, lg){
            margin-top: 35px;
        }

        @include media-breakpoint-up(lg){
            margin-top: 0;
        }
    }

    &__form {
        margin-top: 0;
    }

    &__form-group{
        margin-bottom: 20px;
    }

    &__submit {
        @include media-breakpoint-up(lg){
            margin-top: 16px;
        }

        box-shadow: none;
    }

    &__textarea {
        resize: none;
    }

    &__social-text {
        position: relative;
        margin-left: 34px;
        @include make-vertical-sep(before, $button, 42px, 2px, 100px, -18px, -50%){
            transform: translate(-50%, 0);
        }

        @include media-breakpoint-between(md, lg){
            margin-left: 30px;
        }
    }

    &__icon, &__social-text {
        display: inline-block;
    }

    &__social-label {
        margin-top: 53px;

        @include media-breakpoint-between(md, lg){
            margin-top: 45px;
        }

        @include media-breakpoint-up(lg){
            margin-top: 81px;
        }

    }

    &__links {
        margin-top: 20px;
        margin-left: 63px;

        @include media-breakpoint-between(md, lg){
            margin-top: 27px;
        }

        @include media-breakpoint-up(lg){
            margin-top: 0;
            margin-left: 11px;
        }
    }

    &__link {
        margin-left: 31px;
        background: $button;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @include media-breakpoint-between(md, lg){
            margin-left: 0;
        }

        @include media-breakpoint-up(lg){
            margin-left: 11px;
        }
    }

    &__link:first-child {
        margin-left: 0;
    }

    &__link:last-child{
        @include media-breakpoint-up(md) {
            margin-top: 12px;
        }
    }
}
