.ticket-card {
    height: auto;
    margin-bottom: 30px;

    @include media-breakpoint-up(lg) {
        height: auto;
    }

    &__top {
        position: relative;
        display: flex;
        flex-direction: column;
        mix-blend-mode: normal;
        border-radius: 20px 20px 0 0;
        padding: 16px 12px;
        min-height: 60px;
        justify-content: center;

        @include media-breakpoint-up(lg) {
            padding: 16px;
        }

        &::after {
            content: ' ';
            position: absolute;
            z-index: 1;
            background-size: cover;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            opacity: 0.06;
        }

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            z-index: 2;

            &__label {
                color: white;
                margin: 0;
                font-weight: bold;
                font-size: 16px;
                line-height: 20px;
                flex: 0.9;
                text-transform: capitalize;

                @include media-breakpoint-up(lg) {
                    font-size: 18px;
                    line-height: 22px;
                }
            }
        }
    }

    &__bottom {
        position: relative;
        mix-blend-mode: normal;
        box-shadow: 0 4px 40px rgba(14, 21, 85, 0.1);
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        padding: 8px 10px 12px 10px;
        background-color: $background;

        @include media-breakpoint-up(lg) {
            padding: 12px 16px 24px;
        }

        &__content {
            overflow: auto;
            height: 100%;

            &.reduced {
                height: 70%;

                @include media-breakpoint-up(md) {
                    height: 67%;
                }
            }
        }

        &__details {
            &__title {
                font-size: 14px;
                line-height: 24px;
                font-weight: bold;
                color: $headings-color;
            }

            &__associate-btn {
                position: relative;
		display:none;
                max-width: 225px;
                width: 100%;
                padding-top: 4px;
                padding-bottom: 4px;
                margin-top: 8px;

                .btn__text-gradient {
                    font-size: 12px;
                }
            }

            &__paragraph {
                font-size: 14px;
                line-height: 24px;
                margin-bottom: 4px;

                &:last-child {
                    margin-bottom: 0;
                }

                strong {
                    font-weight: 600;
                }
            }

            &__wrapper {
                display: flex;

                p {
                    flex: 0.5;
                    margin-bottom: 0;
                }
            }
        }

        &__tournament {
            margin-top: 16px;

            &__wrapper {
                display: flex;

                p {
                    margin-bottom: 0;
                    flex: 0.28;

                    &:last-child {
                        flex: 0.44;
                    }
                }
            }
        }


        &__trash-can {
            position: absolute;
            right: 10px;
            bottom: 10px;
            border-radius: 100px;
            width: 30px;
            height: 30px;

            &:hover {
                cursor: pointer;
            }

            .icon {
                margin: 0;
                width: 19px;
                height: 21px;
            }
        }

    }

}
