.filter-handler {
    margin-top: 20px;

    @include media-breakpoint-up(lg) {
        margin-top: 25px;
    }

    &:first-of-type {
        margin-top: 30px;

        @include media-breakpoint-up(lg) {
            margin-top: 36px;
        }
    }

    &__text-box {
        width: 100%;
        margin-bottom: 0;

        @include media-breakpoint-up(lg) {
            max-width: 350px;
        }

        &__label {
            font-weight: 500;
            font-size: 10px;
            line-height: normal;
            text-transform: capitalize;
            margin-bottom: 6px;

            @include media-breakpoint-up(md) {
                font-size: 12px;
                line-height: 20px;
                letter-spacing: 0.01em;
            }

            @include media-breakpoint-up(lg) {
                font-weight: 600;
                margin-bottom: 11px;
            }
        }

        &__subtitle {
            font-size: 12px;
            line-height: 20px;
            text-transform: capitalize;
            margin-top: 5px;
            margin-bottom: 0;
        }
    }

    &__select-option {
        text-transform: capitalize;
        line-height: 20px;
        margin-bottom: 2px;

        @include media-breakpoint-up(md) {
            line-height: 24px;
        }

        .label-2 {
            @include media-breakpoint-up(md) {
                font-size: 12px;
            }

            @include media-breakpoint-up(lg) {
                font-weight: 600;
            }
        }
    }

    &__checkbox-group {
        margin-left: 30px;
        margin-bottom: 0;

        &:first-of-type {
            margin-left: 0;
        }

        & .form-check-label {
            max-width: 130px;

            @include media-breakpoint-between(md, lg) {
                max-width: 122px;
            }

            @include media-breakpoint-up(lg) {
                max-width: 134px;
            }
        }
    }

    &__select-group {
        @include media-breakpoint-up(lg) {
            max-width: 350px;
        }
    }

    &__radio-group {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    &__radio-option {
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        user-select: none;
        width: 32px;
        height: 32px;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        margin-right: 6px;

        &:last-of-type {
            margin-right: 0;
        }

        &:hover input ~ &__checkmark::after,
        & input:checked ~ &__checkmark::after {
            display: block;
        }

        & input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        &__in-text {
            position: absolute;
            text-transform: uppercase;
            z-index: 1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &__checkmark {
            position: relative;
            padding: 5px;
            height: 100%;
            width: 100%;
            background-clip: content-box;
            border-radius: 36px;

            &::after,
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 36px;
                background: white;
            }

            &::before {
                z-index: -1;
                width: calc(100% - 4px);
                height: calc(100% - 4px);
            }

            &::after {
                display: none;
                z-index: -2;
                width: 100%;
                height: 100%;
                background: $button;
            }
        }
    }
}
