.popover {
		position: absolute;
		top: 0;
		left: 0;

		max-width: 316px;

		background: #FFFFFF;
		mix-blend-mode: normal;
		box-shadow: 0 4px 40px rgba(14, 21, 85, 0.1);
		border-radius: 5px;
		border: none !important;

		transition: opacity .15s linear,z-index .05s .2s;

		&--image {
				max-width: none;

				.popover {

						&-body  {
								padding: 10px 40px 30px;
						}

						&__close {
								padding: 10px 10px 0 0;
								line-height: 10px;
								opacity: 1;

								&__icon {
										width: 10px;
										height: 10px;
								}
						}
				}
		}

		&.show {
				transition: opacity .15s linear;
		}

		& .arrow {
				&:before, &:after {
						border-width: 0.5rem 0.75rem 0;
						border-top-color: $white;
						bottom: 1px;
				}
		}

		&__close {
				padding: 14px 14px 0 0 !important;
				line-height: 16px;
				opacity: 1;

				&__icon {
						width: 16px;
						height: 16px;
						margin: 0;
				}
		}

		&-header {
				background-color: $white;
		}

		&-body {
				padding: 14px;
		}

		&__image {
				margin-top: 15px;
		}
}