.product-image-carousel {
    &__item {
        height: 240px;
        padding-left: 52px;
        padding-right: 52px;
        object-fit: contain;

        @include media-breakpoint-up(md) {
            padding-left: 15px;
            padding-right: 15px;
        }

        @include media-breakpoint-up(lg) {
            height: 308px;
            padding-left: 116px;
            padding-right: 116px;
        }
    }

    &__action-button {
        display: flex;
        justify-content: center;
        align-content: center;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        background: transparentize($color: $title, $amount: 0.95);
        width: 24px;
        height: 24px;
        border-radius: 100px;
        cursor: pointer;
        border: none !important;

        @include media-breakpoint-up(md) {
            top: 40%;
            transform: translate(-50%, -40%);
        }

        @include media-breakpoint-up(lg) {
            width: 44px;
            height: 44px;

            svg {
                width: 11px;
                height: 20px;
            }
        }

        &:focus {
            outline: none;
        }

        &__left {
            left: 30px;
            transform: translate(-50%, -50%) scale(-1);

            @include media-breakpoint-up(md) {
                transform: translate(-50%, -40%) scale(-1);
            }

            @include media-breakpoint-up(lg) {
                left: 78px;
            }
        }

        &__right {
            right: 0;

            @include media-breakpoint-between(md, lg) {
                right: 3px;
            }

            @include media-breakpoint-up(lg) {
                right: 33px;
            }
        }
    }

    &__previews {
        position: relative;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 22px;
        padding-bottom: 5px;

        @include media-breakpoint-up(lg) {
            margin-top: 67px;
            max-width: 380px;
            margin-left: auto;
            margin-right: auto;
        }

        &__indicator {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 36px;
            height: 2px;
            background: radial-gradient(28.47px at 21.29% 3.47%, $first-button 0%, $second-button 100%);
            opacity: 0.9;
            border-radius: 100px;

            @include media-breakpoint-up(lg) {
                width: 50px;
            }
        }
    }

    &__preview {
        cursor: pointer;
        width: 36px;
        height: 36px;

        @include media-breakpoint-up(lg) {
            width: 50px;
            height: 50px;
        }
    }
}
