.transactions {
    margin-top: 40px;

    &__total-price {
        &__items {
            display: flex;
            justify-content: flex-end;

            .label:last-child {
                margin-left: 18px;

                @include media-breakpoint-up(md) {
                    padding-right: 21px;
                    margin-left: 117px;
                }

                @include media-breakpoint-up(lg) {
                    padding-right: 101px;
                }

                @include media-breakpoint-up(xl) {
                    padding-right: 38px;
                }
            }
        }

        &__show-more {
            display: flex;
            justify-content: center;
            margin-top: 24px;

            @include media-breakpoint-up(md) {
                margin-top: 18px;
            }

            .btn {
                position: relative;
                align-items: center;
                justify-content: center;
                padding: 0;
                width: 175px;
                height: 31px;

                @include media-breakpoint-up(md) {
                    margin-right: 40px;
                }
            }
        }
    }

    &__table {
        padding-left: 15px;
        padding-right: 15px;

        .table {
            margin-bottom: 0;
            padding: 0;
        }

        &__header {
            border-bottom: 1px solid #F2F2F2;

            &__col {
                padding: 0 !important;
                font-weight: 600;
                line-height: 17px;
                font-size: 10px;
                letter-spacing: 0.05em;
                text-transform: uppercase;
                color: $title;

                padding-bottom: 10px !important;

                &:nth-child(1) {
                    width: 210px;

                    @include media-breakpoint-up(xl) {
                        width: 269px;
                        padding-left: 84px;
                    }
                }

                &:nth-child(2) {
                    width: 150px;
                }

                &:nth-child(3),
                &:nth-child(4) {
                    width: 120px
                }

                &:nth-child(5) {
                    width: 90px;
                }

                &:first-child {
                    padding-left: 73px !important;
                }
            }
        }
    }
}
