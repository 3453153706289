.ticket {
    $ticket: &;
    position: relative;
    //margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    transition-property: background-color, box-shadow;
    transition-duration: .35s;
    transition-timing-function: ease-in;
    max-height: 600px;

    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md){
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        padding-top: 13px;
        padding-bottom: 11px;
    }

    @include media-breakpoint-up(lg) {
        padding-top: 28px;
        padding-bottom: 27px;
        align-items: center;
    }

    &:first-child {
        @include media-breakpoint-up(lg){
            margin-top: 0;
        }
    }

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        height: 1px;
        //width: calc(100% - 30px);
        width: 100%;
        background-color: rgba(29, 15, 81, 0.24);
        transform: translate(-50%,0);
        transition: .3s all;

        opacity: 1;

        //@include media-breakpoint-between(md, lg){
        //    width: calc(100% - 30px);
        //}

        @include media-breakpoint-up(lg){
            visibility: hidden;
        }
    }

    &:hover {
        background: #FFFFFF;
        box-shadow: 0 4px 40px rgba(14, 21, 85, 0.1);
        border-radius: 3px;

        #{$ticket}__shop {
            &__button {
                box-shadow: 2px 1000px 1px rgba(255, 255, 255, 0) inset;
                span {
                    position: relative;
                    z-index: 2;
                    font-weight: $font-weight-semi-bold;
                    font-size: 13px;
                    letter-spacing: 0.05em;
                    text-transform: uppercase;
                    background: $white;
                    -webkit-text-fill-color: $button;
                    -webkit-background-clip: text;
                }
                &--red {
                    span {
                        -webkit-text-fill-color: $button-delete;
                    }
                }
            }
        }

        &::after {
            opacity: 0;
        }
    }

    &--feature {
        @include media-breakpoint-between(md, lg){
            padding-top: 7px;
        }

        @include media-breakpoint-up(lg){
            padding-top: 12px;
            align-items: flex-end;
        }

        #{$ticket}__info {
            @include media-breakpoint-up(lg){
                margin-top: 0;
            }
        }
    }

    &__title {
        user-select: none;
        font-size: 18px;
        letter-spacing: 0.01em;
        margin-bottom: 4px;
        font-weight: 700;

        //@include media-breakpoint-up(lg){
        //    display: inline-block;
        //    width: 195px;
        //    margin-bottom: 2px;
        //}

        //@include media-breakpoint-up(xl) {
        //    width: 233px;
        //}

        &--withFeature {
            margin-top: 4px;
        }
    }

    &__label {
        user-select: none;
        font-weight: 500;
    }

    &__shop,
    &__info {
        margin-right: 15px;
        margin-left: 15px;
    }

    &__info {
        //margin-left: 15px;

        @include media-breakpoint-between(md,lg){
            display: inline-block;
            margin-left: 30px;
        }

        @include media-breakpoint-up(lg){
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            margin-left: 30px;
        }
    }

    &__shop {
        margin-top: 20px;
        //margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include media-breakpoint-up(md){
            margin: 0 30px 0 0;
        }

        @include media-breakpoint-up(lg){
            margin-top: 0;
            margin-right: 15px;
        }

        @include media-breakpoint-up(xl){
            margin-right: 52px;
        }

        &__button {
            position: relative;
            font-weight: 600;
            font-size: 12px;
            color: white;
            height: 31px;
            width: 195px;
            line-height: 0;
            .btn__text-gradient {
                font-size: 12px !important;
            }
            //@include media-breakpoint-up(md){
            //    margin-right: 30px;
            //}
        }
    }

    &__gradientLabel {
        display: inline;
        color: $main-accent;

        @include media-breakpoint-up(lg){
            display: block;
        }

        &--feature {
            flex: 0 1 100%;
            font-size: 12px;
            text-transform: uppercase;
            margin-bottom: 4px;
        }

        &--price {
            user-select: none;
            font-size: 14px;
            margin: 0;
            color: $title;

        }
    }

    &-container {
        transition: all 0.3s ease-in-out;
        @include media-breakpoint-down(lg){
            border-bottom: 1px solid #F2F2F2;
        }
        @include media-breakpoint-up(lg){
            background: #FFFFFF;
            border-radius: 3px;
            transition: all 0.3s ease-in-out;
            margin-bottom: 10px;
        }
    }

    &-container:hover {
        transition: all 0.3s ease-in-out;
        box-shadow: none;
        @include media-breakpoint-up(lg){
            background: #FFFFFF;
            box-shadow: 0 4px 40px rgba(14, 21, 85, 0.1);
            border-radius: 3px;
            transition: all 0.3s ease-in-out;
        }
    }
}
