.announcements {

    &__tag {
        margin: 0;
        width: 79px;
        height: 21px;
        cursor: default !important;
        font-size: 12px !important;
        line-height: 21px;
        text-transform: uppercase;
        padding: 0;
    }

    &__header {
        margin-bottom: 10px;


        @include media-breakpoint-between(md,lg){
            margin-top: 30px;
            margin-bottom: 20px;
            font-size: 36px;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 40px;
            margin-bottom: 42px;
        }
    }

    &__paragraph {
        margin-bottom: 0;
        line-height: 20px;

        @include media-breakpoint-down(sm){
            max-width: 90%;
        }

        @include media-breakpoint-between(md,lg){
            max-width: 380px;
            line-height: 24px;
        }

        @include media-breakpoint-up(lg){
            max-width: 420px;
            line-height: 24px;
        }
    }
}
