.sell-single-ticket-modal {
    
    &__custom-body {
        @include media-breakpoint-up (md) {
            max-width: 480px;
            padding-left: 15px;
            padding-right: 15px;
        }

        @include media-breakpoint-up(lg) {
            max-width: 730px;
        }
    }

    &__form {
        @include media-breakpoint-up(md) {
            margin-top: 0;
            margin-bottom: 60px;
        }
    }

    &__subtitle {
        font-size: 14px;
        margin-bottom: 40px;
        text-align: center;
    }

    &__buttons {
        display: flex;
        max-width: 375px;
        width: 100%;
        justify-content: center;
    }

    &__btn {
        box-shadow: none;
        position: relative;
        width: 100%;
        max-width: 165px;
        height: 31px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;

        &:first-of-type {
            margin-right: 15px;

            @include media-breakpoint-up(md) {
                margin-right: 30px;
                max-width: 160px;
            }
        }
    }
}