@keyframes openSelect {
    from {
        max-height: 0;
    }

    to {
        max-height: 150px;
    }
}

@keyframes openSelectWidth {
    from {
        width: 25%;
    }

    to {
        width: 100%;
    }
}

.combo-box {

    &-container {
        padding: 6px 12px !important;
    }

    &__control {
        cursor: pointer !important;
        border-radius: 2px 2px 0 0 !important;

        &:hover {
            border-color: $title !important;
        }

        &--is-focused {
            border-color: $title !important;
            box-shadow: none !important;
        }

        &--is-disabled {
            background-color: $main-text !important;
        }
    }

    &__placeholder, &__single-value {
        font-weight: 500;
        line-height: 20px;
        font-size: 12px;
        letter-spacing: 0.01em;

        color: $body-color !important;
    }

    &__single-value{
        color: $title !important;
    }

    &__indicator-separator {
        display: none;
    }

    &__dropdown-indicator {
        padding-right: 12px !important;
        padding-left: 12px !important;
    }

    &__menu {
        z-index: 4 !important;
        border-radius: 0 0 2px 2px !important;
        margin: 0 !important;
        border: 1px solid rgba(29, 15, 81, 0.1);
        border-top: none;
        box-shadow: none !important;
        overflow: auto !important;
        &-list {
            transition: height 0.3s ease-out;
        }
    }

    &__option {
        font-weight: 500;
        line-height: 20px;
        font-size: 12px !important;
        padding-left: 6px !important;
        padding-right: 6px !important;
        letter-spacing: 0.01em;
        margin: 2px 6px !important;
        width: calc(100% - 12px) !important;
        cursor: pointer !important;

        color: $title !important;

        &--is-selected,
        &--is-focused {
            background: rgba(30, 15, 82, 0.1) !important;
            border-radius: 2px;
        }
    }
}
