.how-it-works-page {
    margin-top: 63px;
    margin-bottom: 80px;

    @include media-breakpoint-up(md) {
        margin-top: 98px;
        margin-bottom: 156px;
    }

    @include media-breakpoint-up(lg) {
        margin-top: 204px;
        margin-bottom: 138px;
    }

    &__cards-block {
        margin-top: 0;
        margin-bottom: 60px;

        @include media-breakpoint-up(md) {
            margin-bottom: 80px;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 100px;
        }

        .how-it-works__header {
            margin-bottom: 20px;
            line-height: 20px;

            @include media-breakpoint-between(md,lg) {
                line-height: 40px;
                font-size: 30px;
                margin-bottom: 24px;
            }

            @include media-breakpoint-up(lg) {
                line-height: 64px;
                font-size: 48px;
                margin-bottom: 30px;
            }

            &::after {
                display: none;
            }
        }

        .card-deck {
            margin-top: 64px;

            @include media-breakpoint-between(md, lg) {
                margin-top: 120px;
            }

            @include media-breakpoint-up(lg) {
                margin-top: 160px;
            }
        }
    }

    &__subtitle {
        &__label {
            text-align: center;
            font-size: 14px;

            @include media-breakpoint-up(md) {
                font-size: 15px;
            }
        }
    }
}