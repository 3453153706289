.account-change-data-modal {
    @include media-breakpoint-up(md) {
        width: 420px;
    }

    &__modal {
        overflow-y: hidden;
        @include media-breakpoint-up(md) {
            overflow-y: auto;
        }
    }

    &__content {
        border-radius: 8px;
        height: unset;

        &__subtitle {
            margin-top: 20px;
            margin-bottom: 0;
        }

        .modal-header {
            @include media-breakpoint-up(md) {
                flex-direction: column;
                align-items: center;
            }
        }

        .modal-body {
            padding: 0;

            @include media-breakpoint-up(sm) {
                padding-left: 20px !important;
                padding-right: 20px !important;
            }

            @include media-breakpoint-up(lg) {
                padding-left: 35px !important;
                padding-right: 35px !important;
            }

            .container{
                @include media-breakpoint-up(md) {
                    height: unset;
                }
            }
        }

        &__form {
            margin-top: 28px;

            div:nth-last-child(2) .account-change-data-modal__content__form__group {
                @include media-breakpoint-up(sm) {
                    margin-bottom: 30px;
                }
            }

            &__section-title {
                margin-bottom: 16px;

                h5 {
                    margin: 0;
                    font-weight: bold;
                    line-height: normal;
                    letter-spacing: 0.01em;

                    color: $main-text;
                }
            }

            &__group {
                margin-bottom: 23px;
                position: relative;

                label {
                    text-transform: uppercase;
                }

                &__input-wrapper {
                    position: relative;
                    &:invalid {
                        .was-validated & ~ .account-change-data-modal__content__form__group__input-wrapper__eye {
                            //transform: translate(-15px,-145%);//
                            //transform: translateX(-140%);
                            right: 25px;

                            @include media-breakpoint-up(md) {
                                right: 50px;
                            }

                            @include media-breakpoint-up(xl) {
                                right: 25px;
                            }
                        }
                    }

                    &__eye {
                        position: absolute;
                        top: 39px;
                        right: 5px;
                        transform: translateY(-50%);

                        @include media-breakpoint-up(md) {
                            right: 30px;
                        }

                        @include media-breakpoint-up(xl) {
                            right: 5px;
                        }
                    }
                }
            }
        }
    }
}
