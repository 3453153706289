.not-found {
  min-height: 70vh;
  & .title {
    margin-top: 20px;
    @include media-breakpoint-up(lg) {
      font-size: 48px;
    }
    @include media-breakpoint-down(lg) {
      font-size: 32px;
    }
    margin-bottom: 20px;
  }
  & .content {
    color: $main-text;
  }
  & .text-container {
    margin-top: 100px;
    max-width: 500px;
    background-color: transparent;
    position: relative;
    &::after {
      @include media-breakpoint-up(md) {
        content: ' ';
        position: absolute;
        background: url("../../img/404.png") no-repeat;
        z-index: -1;
        top: -55px;
        left: -47px;
        transform: scale(0.7);
        width: 435px;
        height: 165px;
      }

      @include media-breakpoint-up(xl) {
        transform: scale(1.0);
        left: 26px;
        top: -48px;
      }
    }
  }
}
