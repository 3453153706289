.sold-out {
    &__title {
        font-weight: 400;
        line-height: 40px;
        font-size: 32px;
        letter-spacing: 0.01em;

        @include media-breakpoint-between(md, lg) {
            line-height: 40px;
            font-size: 32px;
        }

        @include media-breakpoint-up(lg) {
            line-height: normal;
            font-size: 48px;
        }
    }

    &__subtitle {
        font-size: 16px;
        line-height: 24px;
        &.last {
            margin-bottom: 40px;
        }
    }

    &__btn {
        width: 100%;
        height: 44px;
        box-shadow: none;
        line-height: normal;
    }
}
