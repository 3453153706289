.two-way-modal {

		&__content {
				margin: auto;
				max-width: 285px;
				@include media-breakpoint-up(md) {
						max-width: 350px;
				}
		}

		&__container {
				padding: 24px 4px 0;

				@include media-breakpoint-up(md) {
						padding: 24px 24px 0;
				}
		}

		&__footer {
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 14px 32px 10px;
				@include media-breakpoint-up(md) {
						flex-direction: row;
						justify-content: space-between;
						padding: 14px 0 24px;
				}
		}

		&__button {
				position: relative;
				padding: 3px 58px;
				max-width: 180px;
				margin-bottom: 14px;

				@include media-breakpoint-up(md) {
						padding: 4px 24px;
						max-width: 118px;
						margin-bottom: 0;
				}

				&--min {
					min-width: 180px;

					@include media-breakpoint-up(md) {
						min-width: 118px;
					}
				}
		}
}