.fan-club {
    margin-top: 58px;
    margin-bottom: 80px;

    @include media-breakpoint-between(md, lg) {
        margin-top: 46px;
    }

    @include media-breakpoint-up(lg) {
        margin-top: 160px;
        margin-bottom: 100px;
    }

    &__header {
        margin-bottom: 40px;

        @include media-breakpoint-between(md, lg) {
            font-size: 30px;
            line-height: 40px;
        }

        @include media-breakpoint-up(md) {
            margin-bottom: 28px;
        }

        @include media-breakpoint-up(lg) {
            line-height: 48px;
        }
    }

    &__subtitle {
        line-height: 20px;
        margin-bottom: 28px;

        @include media-breakpoint-up(md) {
            line-height: 24px;
        }

        @include media-breakpoint-between(md, lg) {
            padding-right: 30px;
        }

        @include media-breakpoint-up(lg) {
            padding-right: 95px;
        }
    }

    &__register {
        @include media-breakpoint-down(sm) {
            margin-bottom: 48px;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 50px;
        }

        &__title {
            font-weight: bold;
            line-height: 20px;
            margin-bottom: 10px;

            @include media-breakpoint-up(lg){
                line-height: 22px;
            }
        }

        &__content {
            line-height: 24px;
            margin: 0;

            @include media-breakpoint-between(md, lg) {
                padding-right: 30px;
            }

            @include media-breakpoint-up(lg) {
                padding-right: 95px;
            }
        }
    }

    &__form {

        @include media-breakpoint-between(md, lg) {
            margin-top: 38px;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 62px;
        }

        form {
            .form-group {
                //max-height: 60px;
                margin-bottom: 23px;

                &:last-of-type {
                    margin-bottom: 36px;
                }
            }
        } 

        &__label {
            letter-spacing: 0.01em;
            text-transform: uppercase;
        }

        &__input {
            
        }

        &__submit {
            box-shadow: none;
            height: 51px;
        }
    }

    &__accordion {
        margin-top: 28px;

        @include media-breakpoint-up(md) {
            margin-top: 100px;
        }

        .accordion {
            width: 100%;
        }
    }
}