.member-cards-modal {
    &__header {
        flex-direction: column;
        &--top, &--bottom {
            display: flex;
            height: 100%;
            width: 100%;
        }
    }

    &--custom {
        overflow-y: hidden !important;

        &__body {
            overflow-y: auto;
            height: calc(100vh - 117px) !important;
            min-height: calc(100vh - 117px) !important;
        }
    }

    &__content {
        .modal-header {
            padding-top: 12px !important;
            padding-bottom: 10px !important;

            .close {
                padding-top: 10px !important;
            }
        }

        .modal-body {
            min-height: calc(100vh - 60px);
        }

        &__subtitle {
            font-weight: 600;
            line-height: 20px;
            font-size: 12px;
            margin: 0;
            letter-spacing: 0.01em;

            color: $body-color;
        }

        &__add-member-card {
            margin-top: 18px;
            margin-bottom: 34px;
            position: relative;
            width: 195px;
            height: 31px;

            .btn__text-gradient {
                position: absolute;
                transform: translate(-50%, -50%);
                width: 100%;

                @include media-breakpoint-up(md) {
                    width: 195px;
                }
            }
        }
    }
}
