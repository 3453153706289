.sell {
    margin-top: 34px;
    margin-bottom: 100px;

    @include media-breakpoint-between(md, lg) {
        margin-top: 50px;
        margin-bottom: 120px;
    }

    @include media-breakpoint-up(lg) {
        margin-top: 79px;
        margin-bottom: 150px;
    }

    &__table {

        & ~ & {
            margin-top: 100px;

            @include media-breakpoint-up(md) {
                margin-top: 120px;
            }
        }

        &--sell-page {
            margin-top: 56px;

            @include media-breakpoint-up(md) {
                margin-top: 40px;
            }

            & ~ & {
                @include media-breakpoint-up(md) {
                    margin-top: 64px;
                }
            }

            & .sell__table__title {
                font-size: 14px;
                margin-bottom: 20px;

                @include media-breakpoint-up(md) {
                    margin-bottom: 12px;
                }

                @include media-breakpoint-up(lg) {
                    font-size: 18px;
                }
            }
        }

        &__title {
            font-weight: 600;
            line-height: 20px;
            margin-bottom: 16px;

            @include media-breakpoint-between(md, lg) {
                margin-bottom: 24px;
            }

            @include media-breakpoint-up(lg) {
                margin-bottom: 32px;
                font-size: 18px;
                line-height: 22px;
                font-weight: bold;
            }
        }

        &__single-ticket-wrapper {
            @include media-breakpoint-up(md) {
                display: flex;
                align-items: baseline;
            }
        }

        &__single-ticket-btn {
            position: relative;
            width: 100%;
            max-width: 220px;
            height: 31px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            margin-bottom: 16px;

            @include media-breakpoint-up(md) {
                margin-bottom: 0;
                margin-left: 24px;
            }
        }

        &__header {
            margin-bottom: 10px;

            @include media-breakpoint-up(lg) {
                padding-left: 25px;
                padding-right: 25px;
            }

            &__label {
                letter-spacing: 0.05em;
                text-transform: uppercase;
                color: $main-text;
                font-weight: 600;
                margin-bottom: 0;

                &--match {
                    flex: 0.42;
                    padding-left: 25px;

                    @include media-breakpoint-up(lg) {
                        padding-left: 0;
                        flex: 0.44;
                    }

                    &--acquired {
                        @include media-breakpoint-up(lg) {
                            flex: 0.52;
                        }
                    }
                }

                &--details {
                    flex: 0.58;

                    @include media-breakpoint-up(lg) {
                        flex: 0.38;
                    }

                    &--acquired {
                        @include media-breakpoint-up(lg) {
                            flex: 0.48;
                        }
                    }
                }

                &--refund {
                    flex: 0.38;
                }
            }
        }

        &__filters {
            display: flex;
            border-bottom: 1px solid $lines-and-box;
            margin-bottom: 12px;

            &__filter {
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 38px;
                background: $background;
                border-radius: 4px 0 0 4px;
                flex: 0.5;

                &--tablet {
                    @include media-breakpoint-up(md) {
                        margin-bottom: 32px;
                    }
                }

                &--right {
                    border-radius: 0 4px 4px 0;
                    margin-left: 1px;
                }

                &__name {
                    text-transform: capitalize;
                    color: $title;
                    font-weight: 600;
                    line-height: 20px;
                }
            }
        }
    }
}
