
main{
  .react-tel-input{
    .form-control{
      width: 100%;
      max-width: 100%;
      padding-right: 4px;
      padding-left: 46px;
      &input{
        width: 100%;
      }
    }
    .flag-dropdown.open{
      z-index: 10;
      .country-list{
        margin: 2px 0 10px -1px;
        z-index: 10;
      }
    }

  }
}
