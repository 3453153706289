.payout-history {
  margin-top: 100px;

  & .title {
    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #1D0F51;
  }

  & .table-header-text {
    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 170.66%;
  }

  & .header {
    border-bottom: 1px solid $lines-and-box;
  }

  & .table-footer {
    margin-top: 30px;
    justify-content: end;
    align-items: center;
    @include media-breakpoint-down(lg) {
      justify-content: space-between;
    }

    & .text {
      font-family: 'Montserrat', serif;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 170.66%;
      letter-spacing: 0.01em;
    }
  }

  & .payout-history-ticket {
    & .icon {
      width: 28px;
      height: 28px;
    }

    & .lens {
      cursor: pointer;
      width: 19px;
      height: 19px;
    }

    & .date {
      font-family: 'Montserrat', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #5E7891;
      @include media-breakpoint-down(lg) {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.01em;
      }
    }

    & .price {
      font-family: 'Montserrat', serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }

    & .ticket-type {
      font-family: 'Montserrat', serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #191414;
      @include media-breakpoint-down(lg) {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.01em;
      }
    }

    & .ticket-content {
      font-family: 'Montserrat', serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #5E7891;
      @include media-breakpoint-down(lg) {
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.01em;
      }
    }

    & .no-ticket-found {
      padding-top: 20px;
      padding-bottom: 20px;
      font-size: 16px;
    }
  }

  & .payout-history-filters {
    & .filter-title {
      font-family: 'Montserrat', serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.01em;
    }
  }
}
