.ticket-cards {
    margin-top: 34px;
    margin-bottom: 100px;


    @include media-breakpoint-between(md, lg) {
        margin-top: 50px;
        margin-bottom: 120px;
    }

    @include media-breakpoint-up(lg) {
        margin-top: 67px;
        margin-bottom: 150px;
    }

    &__generic-form {
        .row .form-group label {
            text-transform: uppercase;
        }
    }


    &__header {

        @include media-breakpoint-up(lg) {
            display: flex;
            align-items: center;
        }

        &__title {
            &__label {
                color: map_get($theme-colors, "paragraph-color");
                line-height: 20px;
                margin: 0;

                @include media-breakpoint-up(lg) {
                    font-size: 14px;
                }
            }
        }

        &__button {
            width: 100%;
            margin-top: 19px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 48px;

            @include media-breakpoint-between(md, lg) {
                margin-top: 24px;
            }

            @include media-breakpoint-up(lg) {
                margin: 0;
            }

            &:hover,
            &:active,
            &:focus{
                .icon {
                    background: white;
                }
            }

            .icon {
                width: 24px;
                height: 16px;
                z-index: 1;
            }
        }
    }

    &__search-box {
        margin-bottom: 32px;
        padding: 0;

        & input[type="text"] {
            border-radius: 2px !important;
        }

        &__button {
            position: absolute;
            height: 12px;
            width: 12px;
            display: flex;
            justify-content: center;
            padding: 0;
            right: 14px;
            top: 50%;
            transform: translateY(-50%);
            background: none;
            z-index: 4;
            opacity: 1;
            transition: opacity .3s;

            &.empty {
                opacity: 0;
            }
        }
    }

    &__modal {
        @include media-breakpoint-down(sm) {
            margin: 0;
            padding: 0;
            height: 100%;
            max-width: none;
            transform: none !important;
        }

        @include media-breakpoint-up(md) {
            max-width: 570px;
            display: flex;
            align-items: center;
            min-height: calc(100% - (0.5rem * 8));
        }

        @include media-breakpoint-up(lg) {
            max-width: 730px;
        }

        &__step-counter {
            position: relative;
            display: flex;
            justify-content: space-between;
            margin-left: auto;
            margin-right: auto;
            max-width: 195px;

            @include media-breakpoint-up(md) {
                max-width: 282px;
            }

            &::after {
                content: '';
                position: absolute;
                height: 2px;
                background: $title;
                opacity: 0.1;
                border-radius: 2px;
                width: calc(100% - 86px);
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            &__step {
                background: #FFFFFF;
                box-shadow: 0 4px 40px rgba(14, 21, 85, 0.1);
                border-radius: 30px;
                width: 31px;
                height: 31px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 500;
                font-size: 16px;

                &.active {
                    background: $title;
                    font-weight: bold;
                    color: white;
                }
            }
        }

        &__custom-body {
            @include media-breakpoint-down(md) {
                margin-bottom: 30px;
                padding-bottom: 40px;
            }

            //@include media-breakpoint-down(sm) {
            //    margin-bottom: 30px;
            //}

            @include media-breakpoint-up(md) {
                padding-top: 10px;
                padding-bottom: 40px;
            }

            &__subtitle {
                margin-left: auto;
                margin-right: auto;
                line-height: 24px;

                @include media-breakpoint-down(sm) {
                    margin-top: 30px;
                }

                @include media-breakpoint-between(md, lg) {
                    max-width: 450px;
                }

                @include media-breakpoint-up(lg) {
                    max-width: 460px;
                }
            }
        }

        &__buttons {
            width: 100%;
            @include media-breakpoint-down(sm) {
                pointer-events: none;
                position: fixed;
                bottom: 15px;
                width: 100%;
                max-width: 540px;
            }

            @include media-breakpoint-down(md) {
                position: absolute;
                bottom: 0;
            }

            & button,
            & a {
                pointer-events: all;
            }
        }

        &__stepper{
            margin-left: auto;
            margin-right: auto;
            //max-width: 230px;
        }

        &__content {
            z-index: 2;
            @include media-breakpoint-down(sm) {
                height: auto;
                min-height: 100%;
                border-radius: 0;
                border: none;
            }

            &__summary {
                margin-top: 60px;

                &__title {
                    font-weight: 600;
                    color: $headings-color;
                    text-align: center;
                    line-height: 20px;

                    @include media-breakpoint-up(md) {
                        line-height: 24px;
                    }
                }

                &__subscription {
                    margin-top: 12px;
                    margin-left: auto;
                    margin-right: auto;
                    max-width: 285px;
                    padding: 12px 20px;
                    background: #FFFFFF;
                    border: 1px solid rgba(29, 15, 81, 0.24);
                    box-sizing: border-box;
                    border-radius: 8px;

                    @include media-breakpoint-between(md, lg) {
                        max-width: 330px;
                        margin-bottom: 87px;
                    }

                    @include media-breakpoint-up(lg) {
                        max-width: 460px;
                        margin-bottom: 60px;
                    }

                    &__header {
                        justify-content: space-between;
                        align-items: center;
                        display: flex;
                        margin-bottom: 8px;

                        &__title {
                            margin-bottom: 0;
                            flex: 0.8;
                        }

                        &__options {
                            display: flex;
                            justify-content: space-between;
                            flex: 0.15;
                        }
                    }

                    &__details {
                        display: flex;
                        flex-direction: column;

                        &__label {
                            font-size: 10px;
                            line-height: 12px;
                            margin-bottom: 2px;

                            @include media-breakpoint-between(md, lg) {
                                font-size: 12px;
                                line-height: 15px;
                            }

                            @include media-breakpoint-up(lg) {
                                font-size: 14px;
                                line-height: 24px;
                            }
                        }

                        &__wrapper {
                            display: flex;

                            &__content {

                                &:first-child {
                                    flex: 0.4;
                                }

                                &:last-child {
                                    flex: 0.6;
                                }
                            }
                        }
                    }
                }
            }

            &__form {
            }

            &__member-card {
                margin-top: 40px;
                width: 100%;
                display: flex;
                flex-direction: column;
                background: white;
                box-shadow: 0 4px 40px rgba(14, 21, 85, 0.1);
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
                padding: 25px 10px 20px;
                position: relative;

                &--offset {
                    @include media-breakpoint-up(md) {
                        margin-bottom: 80px;
                    }
                }

                &__top {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    &__name {
                        margin-bottom: 0;
                        font-weight: 600;
                        line-height: 22px;
                        color: $main-text;
                        text-transform: capitalize;
                        flex: 0.9;

                        @include media-breakpoint-up(lg) {
                            font-size: 24px;
                            line-height: 29px;
                        }
                    }

                    &__icon {
                        cursor: pointer;
                        margin-right: 0;

                        &:nth-child(1) {
                            margin-right: 10px;
                        }
                    }
                }

                &__bottom {
                    margin-top: 12px;

                    @include media-breakpoint-up(md) {
                        display: flex;
                        justify-content: space-between;
                    }

                    &__wrapper {
                        display: flex;

                        @include media-breakpoint-down(sm) {
                            margin-top: 8px;
                        }

                        @include media-breakpoint-up(md) {
                            flex: 0.7;
                        }

                        & > div {
                            flex: 0.5;
                        }

                        & div:first-child {
                            @include media-breakpoint-up(md) {
                                flex: 0.6;
                            }
                        }

                        & div:last-child {
                            @include media-breakpoint-up(md) {
                                flex: 0.4;
                            }
                        }
                    }

                    &__birthday {
                        display: inline-flex;
                        align-items: flex-start;

                        @include media-breakpoint-up(md) {
                            flex: 0.3;
                        }

                        &__pin {
                            position: relative;
                            padding-left: 24px;
                        }

                        p {
                            display: flex;
                            white-space: pre-wrap;
                            margin-bottom: 0;
                            line-height: 24px;
                            font-size: 14px;

                            & .icon {
                                position: absolute;
                                margin: 0;
                                left: 0;
                                width: 16px;
                                height: 15px;
                                top: calc(50% - 1.5px);
                                transform: translateY(-50%);
                                display: flex;
                            }
                        }
                    }
                }
            }

            &__insert-subscription {
                display: flex;
                flex-direction: column;
                margin-top: 50px;

                @include media-breakpoint-between(md, lg) {
                    margin-top: 60px;
                    margin-bottom: 135px;
                }

                @include media-breakpoint-up(lg) {
                    margin-top: 32px;
                    margin-bottom: 118px;
                }

                &__long-paragraph {
                    margin: 26px 42.5px;
                    font-size: 12px;
                }

                &__btn-wrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    &__btn {
                        width: 205px;
                        height: 31px;
                        padding-top: 4px;
                        padding-bottom: 4px;
                        box-shadow: none;
                    }
                }

                &__text-btn {
                    cursor: pointer;
                    font-weight: 600;
                    font-size: 10px;
                    line-height: 17px;
                    letter-spacing: 0.05em;
                    background: $button;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    margin-bottom: 20px;
                }

                &__of-course-title {
                    margin-top: 40px;
                    margin-bottom: 32px;
                }

                &__of-course-title,
                &__title {
                    text-align: center;
                    font-weight: 600;
                    line-height: 20px;
                    color: $headings-color;
                }

                &__title {
                    @include media-breakpoint-down(sm) {
                        max-width: 250px;
                        margin-left: auto;
                        margin-right: auto;
                    }
                }

                &__wrapper {
                    margin-top: 32px;
                    display: flex;
                    justify-content: space-between;
                    max-width: 275px;
                    width: 100%;
                    margin-left: auto;
                    margin-right: auto;

                    &__btn {
                        position: relative;
                        width: 100%;
                        max-width: 125px;
                        height: 51px;
                        box-shadow: none;
                    }
                }
            }

            &__compile-options {
                max-width: 303px;
                margin-left: auto;
                margin-right: auto;
                display: flex;
                // justify-content: space-between; Remove for the autocomplete feature
                justify-content: center;
                align-content: center;

                @include media-breakpoint-up(md) {
                    margin-bottom: 190px;
                }

                &__subtitle {
                    margin-top: 30px;
                    margin-bottom: 100px;

                    @include media-breakpoint-up(md) {
                        margin-top: 0;
                    }
                }

                &__btn-continue {
                    padding-top: 4px;
                    padding-bottom: 4px;
                    max-width: 205px;
                    height: 31px;
                    box-shadow: none;

                    @include media-breakpoint-up(md) {
                        max-width: 160px;
                    }
                }

                &__option {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    cursor: pointer;

                    &:hover,
                    &.active {
                        .ticket-cards__modal__content__compile-options__option__description {
                            font-weight: 600;
                        }
                    }

                    &:not(:hover) {
                        &:not(.active) {
                            svg rect {
                                stroke: #BDBDBD;
                            }

                            svg path {
                                fill: #BDBDBD;
                            }
                        }
                    }

                    svg {
                        @include media-breakpoint-up(md) {
                            width: 160px;
                            height: 160px;
                        }
                    }

                    &__description {
                        font-size: 12px;
                        text-align: center;
                        margin-top: 10px;
                        letter-spacing: 0.01em;
                        margin-bottom: 0;
                    }
                }
            }

            .modal-header {
                padding: 22.25px;
                border: none;

                @include media-breakpoint-down(sm) {
                    background: #FFFFFF;
                    mix-blend-mode: normal;
                    box-shadow: 0 4px 40px rgba(14, 21, 85, 0.1);
                }

                .modal-title {
                    font-weight: 600;
                    line-height: normal;
                    font-size: 16px;
                    text-align: center;
                    color: map_get($theme-colors, "heading-color");

                    @include media-breakpoint-up(md) {
                        font-weight: 500;
                    }

                    @include media-breakpoint-up(lg) {
                        font-size: 18px;
                    }
                }

                .close {
                    padding: 0;
                    margin: 0;
                    width: 19.5px;
                    height: 19.5px;

                    .icon {
                        margin: 0;
                        width: 19.5px;
                        height: 19.5px;
                    }
                }
            }

            .modal-body {
                @include media-breakpoint-up(lg) {
                    padding-left: 35px;
                    padding-right: 35px;
                }

                .form-check {
                    margin-top: 26px;
                    margin-bottom: 16px;
                }

                .form-group input,
                .form-group .custom-select {
                    font-weight: 500;
                    line-height: 20px;
                    font-size: 12px;
                    letter-spacing: 0.01em;
                    color: map_get($theme-colors, "paragraph-color");
                }

                .form-group .custom-select {
                    background: #FFFFFF url("../../img/icon/arrows/select-arrow.svg") no-repeat right 0.75rem center;
                    background-size: 9px 5px;

                    border: 1px solid rgba(29, 15, 81, 0.1);
                }
            }

            &__buttons {
                display: flex;
                justify-content: center;
                margin-top: 30px;

                @include media-breakpoint-up(md) {
                    margin-top: 14px;
                    margin-bottom: 24px;
                }

                .btn {
                    position: relative;
                    align-items: center;
                    height: 31px;
                    width: 165px;
                    padding: 0;

                    &:first-child {
                        margin-right: 15px;

                        @include media-breakpoint-up(md) {
                            margin-right: 30px;
                        }
                    }
                }
            }
        }
    }

    &__list {
        margin-top: 48px;

        @include media-breakpoint-between(md, lg){
            margin-top: 58px;
        }
    }
}
