.homepage {
		.card-deck {

				.card-group__card-noshadow {
						.card-title{
								margin-bottom: 8px;

								@include media-breakpoint-up(lg) {
										margin-bottom: 11px;
								}
						}
				}

				.card {
						margin-bottom: 10px;

						&-title {
								margin-bottom: 15px;
						}
				}
		}
}
