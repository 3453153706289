.page-number {
    width: 24px;
    height: 24px;
    margin-left: 6px;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;


    &.active,
    &:hover {
        .page-number__label {
            color: white;
        }

        background: $button;
    }

    &__label {
        cursor: pointer;
        font-weight: 500;
        line-height: normal;
        font-size: 16px;
        text-align: center;
        letter-spacing: 0.01em;
        color: $body-color;
    }
}
