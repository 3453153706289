.account {
    margin-top: 60px;
    margin-bottom: 120px;
    min-height: 60vh;

    @include media-breakpoint-down(sm){
        margin-left: 8px;
        margin-right: 8px;
    }

    @include media-breakpoint-up(lg) {
        margin-top: 80px;
        margin-bottom: 200px;
    }

    &__dati {
        margin-bottom: 33px;

        &__fields {
            display: flex;
            flex-direction: column;
        }
    }

    &__fatturazione {

        &__empty {
            line-height: 24px;
            font-size: 14px;
        }

    }

    &__spedizione {
        margin-top: 80px;

        @include media-breakpoint-between(md, lg) {
            margin-top: 120px;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 150px;
        }
    }

    &__header {
        display: flex;
        align-items: baseline;
        margin-bottom: 26px;

        &__title {
            color: $title;
            font-weight: 600;
            margin-bottom: 0;

            border-right: 1px solid #D0D0D0;
            padding-right: 8px;
        }

        &__edit {
            width: fit-content;
            cursor: pointer;
        }
    }

    &__field {
        display: flex;
        flex-direction: column;
        margin-bottom: 28px;

        &__label {
            font-weight: 600;
            margin-bottom: 2px;
            text-transform: capitalize;
        }

        &__value {
            color: map_get($theme-colors, "paragraph-color");
            margin-bottom: 0;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    &__delete {
        margin-bottom: 100px;
        &__title {
            color: map_get($theme-colors, "paragraph-color");
            font-weight: 600;
            margin-bottom: 26px;
            margin-top: 60px;

            @include media-breakpoint-between(md, lg) {
                margin-top: 80px;
            }

            @include media-breakpoint-up(lg) {
                margin-top: 100px;
            }
        }

        &__button {
            position: relative;
            width: 180px;
            height: 31px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            margin-top: 12px;
        }
    }
}

.card-profile-account {
    width: 284px;
    height: 141px;
    background: #FFFFFF;
    box-shadow: 0 4px 40px rgba(14, 21, 85, 0.1);
    border-radius: 3px;
    flex-direction: row;
    margin: 0;

    &__complete {
        background-image: url("../../img/icon/profile-payout-verified.svg");
        background-repeat: no-repeat;
        background-position: right top;
    }

    &__name {
        height: fit-content;
        span {
            font-family: 'Montserrat', serif;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
        }
        padding-top: 10px;
        padding-bottom: 7px;
    }

    &__body {
        height: fit-content;
        span {
            font-family: 'Montserrat', serif;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 12px;
            letter-spacing: 0.03em;
            color: #5E7891;

            &.warning-text {
                font-weight: 500;
                font-size: 16px ;
                letter-spacing: 0.01em;
                color: #E30037;
            }

            &.warning-to-complete:hover {
                cursor: pointer;
                text-decoration: underline;
            }

        }

    }
}
